import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class RagMetrics {
    public readonly storeId: string;
    public readonly areaHealth: number;
    public readonly catchment: number;
    public readonly competition: number;
    public readonly footfall: number;

    constructor(
        storeId: string,
        areaHealth: number,
        catchment: number,
        competition: number,
        footfall: number
    )
    {
        this.storeId = storeId;
        this.areaHealth = areaHealth;
        this.catchment = catchment;
        this.competition = competition;
        this.footfall = footfall;
    }
}

export const loadRagMetrics = (clientId: string): AppThunk<Promise<RagMetrics[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "RC_RagMetrics.AreaHealth",
                "RC_RagMetrics.Catchment",
                "RC_RagMetrics.Competition",
                "RC_RagMetrics.Footfall"
            ],
            filters: [{
                member: "RC_RagMetrics.Client_ID",
                operator: "equals",
                values: [clientId]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return rawData.map(row => new RagMetrics(
            String(row["D_Store.StoreNaturalID"]),
            row["RC_RagMetrics.AreaHealth"] ?? 0,
            row["RC_RagMetrics.Catchment"] ?? 0,
            row["RC_RagMetrics.Competition"] ?? 0,
            row["RC_RagMetrics.Footfall"] ?? 0
        ));
    } catch (error) {
        dispatch(logError("Error loading RAGMetrics.", error));
        throw error;
    }
};
