import { combineReducers } from "redux";

import location from "./location";
import product from "./product";
import report from "./report";

const reducer = combineReducers({
    location,
    product,
    report
});

export default reducer;
