import React, { useState } from "react";

import { Backdrop as MuiBackdrop, Box, Card, CardContent, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        color: theme.palette.common.white,
        width: theme.spacing(75)
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(5),
        "&:last-child": {
            padding: theme.spacing(5)
        }
    }
}))(CardContent);

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: Number.MAX_SAFE_INTEGER,
        color: theme.palette.common.white
    }
}));

interface LocationBackdropProps {
    isLocationBackdropOn: boolean
}

interface LoadingScreenMessage {
    message: string,
    timeout: number
}

const LocationBackdrop: React.FC<LocationBackdropProps> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { isLocationBackdropOn } = props;

    const loadingScreenMessages: LoadingScreenMessage[] = [
        {
            message: "Dash is evaluating over 250,000 retail locations across the UK to determine their alignment with your targets.",
            timeout: 5000
        },
        {
            message: "Each location is evaluated from very strong to very weak, streamlining your process to identify prime candidates or quickly dismiss unsuitable options.",
            timeout: 6000
        },
        {
            message: "Initially, the UK map displays data at the local authority level, highlighting areas with a high density of locations that strongly align with your targets.",
            timeout: 7000
        },
        {
            message: "Dive deeper by zooming into the map to transition from local authority views down to individual retail locations.",
            timeout: 5000
        },
        {
            message: "Effortlessly switch perspectives: choose Overall or a specific chapter's alignment from the dropdown to adjust the map view.",
            timeout: 5000
        }
    ];

    const [loadingScreenMessagesIndex, setloadingScreenMessagesIndex] = useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setloadingScreenMessagesIndex((prevIndex) => (prevIndex + 1) % loadingScreenMessages.length);
        }, loadingScreenMessages[loadingScreenMessagesIndex].timeout);

        return () => clearInterval(interval);
    });

    return (
        <MuiBackdrop className={classes.backdrop} open={isLocationBackdropOn}>
            <StyledCard elevation={0}>
                <StyledCardContent>
                    <Typography variant="h2" component="div" align="center" style={{ paddingBottom: theme.spacing(5) }}>
                        Preparing Location
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress color="primary" style={{ height: theme.spacing(9), width: theme.spacing(9)}} />
                    </Box>
                    <Typography variant="h4" component="div" align="center" gutterBottom style={{ paddingTop: theme.spacing(5) }}>
                        {loadingScreenMessages[loadingScreenMessagesIndex].message}
                    </Typography>
                </StyledCardContent>
            </StyledCard>
        </MuiBackdrop>
    );
};

export default LocationBackdrop;
