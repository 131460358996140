import React from "react";

import { Card, CardContent, Chip, Collapse, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import { CannibalisationPerOutputArea } from "modules/customer/insights/portfolioNew/competition/competitionSlice";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(28),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledChip = withStyles(theme => ({
    root: {
        backgroundColor: (props: any) => props["data-background-color"],
        width: theme.spacing(3),
        height: theme.spacing(1.5),
        outline: (props: any) => props["outline"],
    }
}))(Chip);

const StyledDivider = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))(Divider);

interface LegendProps {
    outputArea?: CannibalisationPerOutputArea
}

const Legend: React.FC<LegendProps> = (props) => {
    const { outputArea } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Collapse in={outputArea !== undefined}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Number of stores with overlapping catchment areas:
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {String(outputArea?.levelOfCannibalisation ?? 0)}
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Number of contested people:
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toDecimalPlaces(outputArea?.sharedCustomers ?? 0, 0)}
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Likelihood of visiting:
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toPercentage(outputArea?.likelihoodOfVisiting ?? 0, false)}
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Total population:
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toDecimalPlaces(outputArea?.totalPopulation ?? 0, 0)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" component="div">
                                Customer profile:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" component="div" align="right" noWrap>
                                {outputArea?.customerProfile ?? ""}
                            </Typography>
                        </Grid>
                    </Grid>
                    <StyledDivider />
                </Collapse>
                <Typography variant="body1" component="div" gutterBottom style={{ color: theme.palette.text.disabled }}>
                    Number of stores with overlapping catchment areas
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.cannibalisation[5]} />
                    &nbsp;&nbsp;Very high ({">"} 4 stores)
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.cannibalisation[4]} />
                    &nbsp;&nbsp;High (3 stores)
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.cannibalisation[3]} />
                    &nbsp;&nbsp;Medium (2 stores)
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.cannibalisation[2]} />
                    &nbsp;&nbsp;Low (1 store)
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.cannibalisation[1]} />
                    &nbsp;&nbsp;None (selected store catchment)
                </Typography>
                <Typography variant="body1" component="div">
                    <StyledChip size="small" data-background-color={colourPalette.cannibalisation[0]} outline={"#9C9AA6 solid 1px"} />
                    &nbsp;&nbsp;None (existing store catchment)
                </Typography>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
