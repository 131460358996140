import { createAppAsyncThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { ExistingStore } from "./existingStore";

interface OutputArea {
    code: string,
    numberOfCustomers: number,
    likelihoodOfVisting: number,
    totalPopulation: number,
    customerProfile: string
}

export class CatchmentArea {
    public readonly retailCentreId: number;
    public readonly storeCategoryId: number;
    public readonly outputAreas: OutputArea[];

    constructor(
        retailCentreId: number,
        storeCategoryId: number,
        outputAreas: OutputArea[]
    ) {
        this.retailCentreId = retailCentreId;
        this.storeCategoryId = storeCategoryId;
        this.outputAreas = outputAreas;
    }
}

interface CatchmentCustomerProfilesDimensions {
    "CatchmentCustomerProfiles.RetailCentreID": number,
    "CatchmentCustomerProfiles.StoreCategory_ID": number,
    "CatchmentCustomerProfiles.OAID": string,
    "CatchmentCustomerProfiles.Population": number,
    "CatchmentCustomerProfiles.Probability": number,
    "CatchmentCustomerProfiles.SupergroupName": string
}

export const loadCatchmentAreas = createAppAsyncThunk<
    CatchmentArea[],
    {
        stores: ExistingStore[],
        catchmentAccountId: string
    }
>(
    "customer/insights/portfolio/competition/loadCatchmentAreas",
    async ({ stores, catchmentAccountId }, thunkAPI) => {
        try {
            if (stores.length === 0) {
                return [];
            }

            const retailCentreIdsByStoreCategoryId = new Map<number, number[]>();
            stores.forEach(store => {
                const retailCentreIds = retailCentreIdsByStoreCategoryId.get(store.storeCategoryId) ?? [];
                retailCentreIds.push(store.retailCentreId);
                retailCentreIdsByStoreCategoryId.set(store.storeCategoryId, retailCentreIds);
            });

            const orFilterClause = { or: [] };
            retailCentreIdsByStoreCategoryId.forEach((retailCentreIds, storeCategoryId) => {
                const andFilterClause = {
                    and: [{
                        member: "CatchmentCustomerProfiles.StoreCategory_ID",
                        operator: "equals",
                        values: [String(storeCategoryId)]
                    }, {
                        member: "CatchmentCustomerProfiles.RetailCentreID",
                        operator: "equals",
                        values: retailCentreIds.map(String)
                    }]
                };
                // @ts-ignore
                orFilterClause.or.push(andFilterClause);
            });
            const segmentName = "Baseline";

            const query = {
                dimensions: [
                    "CatchmentCustomerProfiles.RetailCentreID",
                    "CatchmentCustomerProfiles.StoreCategory_ID",
                    "CatchmentCustomerProfiles.OAID",
                    "CatchmentCustomerProfiles.Population",
                    "CatchmentCustomerProfiles.Probability",
                    "CatchmentCustomerProfiles.SupergroupName"
                ],
                filters: [orFilterClause],
                segments: ["CatchmentCustomerProfiles." + segmentName]
            };

            const resultSet = await thunkAPI.dispatch(cubeLoadCatchmentArea(query, catchmentAccountId, "CatchmentCustomerProfiles")) as unknown as ExtendedResultSet<CatchmentCustomerProfilesDimensions>;
            const rawData = resultSet.loadResponses[0].data;

            const catchmentAreas = stores.map(store => {
                const relevantRecords = rawData.filter(row => row["CatchmentCustomerProfiles.RetailCentreID"] === store.retailCentreId
                    && Number(row["CatchmentCustomerProfiles.StoreCategory_ID"]) === store.storeCategoryId);

                const outputAreas: OutputArea[] = relevantRecords.map(row => ({
                    code: row["CatchmentCustomerProfiles.OAID"],
                    numberOfCustomers: row["CatchmentCustomerProfiles.Probability"] * row["CatchmentCustomerProfiles.Population"],
                    likelihoodOfVisting: row["CatchmentCustomerProfiles.Probability"],
                    totalPopulation: row["CatchmentCustomerProfiles.Population"],
                    customerProfile: row["CatchmentCustomerProfiles.SupergroupName"]
                }));

                return new CatchmentArea(
                    store.retailCentreId,
                    store.storeCategoryId,
                    outputAreas
                );
            });

            return catchmentAreas;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading CatchmentAreas.", error));
            throw error;
        }
    }
);
