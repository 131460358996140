import { PivotConfig, Query, ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export interface CatchmentCustomerProfiles {
    storeId: string;
    [key: string]: number | string | boolean;
}

export const loadCatchmentCustomerProfiles = (clientId: string): AppThunk<Promise<CatchmentCustomerProfiles[]>> => async (dispatch) => {
    try {
        const query: Query = {
            measures: [
                "ClientStoresCatchmentPopulation.SumWeightedPopulation"
            ],
            dimensions: [
                "D_Store.StoreNaturalID",
                "ClientStoresCatchmentPopulation.SupergroupName",
            ],
            filters: [{
                member: "ClientStoresCatchmentPopulation.Client_ID",
                operator: "equals",
                values: [clientId]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const pivotConfig: PivotConfig = {
            x: ["D_Store.StoreNaturalID", 'measures'],
            y: ["ClientStoresCatchmentPopulation.SupergroupName"]
        };
        return resultSet.tablePivot(pivotConfig).map(row => {
            const storeId = String(row["D_Store.StoreNaturalID"]);
            delete row["D_Store.StoreNaturalID"];
            delete row["measures"];
            return {
                storeId,
                ...row
            };
        });
    } catch (error) {
        dispatch(logError("Error loading CatchmentCustomerProfiles.", error));
        throw error;
    }
};
