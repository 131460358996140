import React from "react";

import { Box, Slider, withStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectLikelihoodOfVisitingThresholds, setLikelihoodOfVisitingThresholds } from "modules/customer/insights/portfolioNew/competition/competitionSlice";

const StyledSlider = withStyles(theme => ({
  root: {
        width: theme.spacing(38)
  },
  rail: {
      opacity: 1,
      color: theme.palette.text.disabled
  },
  thumb: {
      height: theme.spacing(1.75),
      width: theme.spacing(1.75),
      color: theme.palette.primary.main,
      border: "2px solid",
      borderColor: theme.palette.common.white,
      "&:focus, &:hover": {
          boxShadow: "none"
      },
      "&.Mui-disabled ": {
          display: "none"
      }
  }
}))(Slider);

const LikelihoodOfVisitingSlider: React.FC = () => {
    const theme = useTheme();
    const filterValue = useAppSelector(selectLikelihoodOfVisitingThresholds);
    const [value, setValue] = React.useState(filterValue);
    const dispatch = useAppDispatch();
    const selectedStore = useAppSelector(selectStore);
    const label = `Likelihood of visiting ${selectedStore?.name} store`;
    
    const handleSliderChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        setValue(value as number[]);
    };

    const handleSliderChangeCommmitted = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        const newValue = value as number[];
        dispatch(setLikelihoodOfVisitingThresholds(newValue));
        setValue(newValue);
    };

    return (
        <Box>
            <Box style={{ width: theme.spacing(38) }}>
                {label}
            </Box>
            <StyledSlider
                value={value}
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChangeCommmitted}
                valueLabelDisplay="auto"
            />
        </Box>
    );
};

export default LikelihoodOfVisitingSlider;
