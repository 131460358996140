import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { DateTime } from "luxon";
import { distance, sqrt, sum } from "mathjs";
import { ViewportProps } from "react-map-gl";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DataWrapper } from "domain/dataWrapper";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";

import { clearAreaHealth, loadAreaHealth } from "./areaHealth/areaHealthSlice";
import { clearCannibalisation, loadCannibalisation } from "./cannibalisation/cannibalisationSlice";
import { clearCompetition, loadCompetition } from "./competition/competitionSlice";
import { clearDemographics, loadDemographics } from "./demographics/demographicsSlice";
import { loadDirectCompetitorNames } from "./filters/directCompetitorNames";
import { selectCandidateTargetStoreCategory } from "./filters/filtersSlice";
import { clearFootfall, loadFootfall } from "./footfall/footfallSlice";
import { clearOverview, loadOverview } from "./overview/overviewSlice";
import { clearSavedLocations } from "./savedLocations/savedLocationsSlice";
import { clearSpend, loadSpend } from "./spend/spendSlice";
import { clearSpend as clearSpendNew, loadSpend as loadSpendNew } from "./spendNew/spendSlice";

import { CatchmentCustomerProfile, loadCatchmentCustomerProfiles } from "./catchmentCustomerProfile";
import { CatchmentPopulation, loadCatchmentPopulation } from "./catchmentPopulation";
import { ClientRegistration, loadClientRegistration } from "./clientRegistration";
import { ComparatorsByChapter } from "./comparator";
import { PinnedLocation } from "./pinnedLocation";
import {
    loadRetailCentres,
    loadStaticRetailCentres,
    RetailCentre,
    RetailCentreWithScores,
    ScoreField,
    StaticRetailCentre
} from "./retailCentre";
import { loadSalesReferenceDate } from "./salesReferenceDate";
import { SavedLocationDetails } from "./savedLocationDetails";
import { loadSpendCategories, SpendCategory } from "./spendCategory";
import { loadStores, Store } from "./store";
import { loadStoreCategories, StoreCategory } from "./storeCategory";
import { Target } from "./target";

export enum LocationChapter {
    Overview = 1,
    Demographics,
    Spend,
    Competition,
    AreaHealth,
    Footfall,
    Cannibalisation
}

interface LoadLocationResponse {
    clientRegistration: ClientRegistration,
    directCompetitorNames: string[],
    salesReferenceDate: DateTime,
    staticRetailCentres: Map<number, StaticRetailCentre>,
    stores: Store[],
    storeCategories: StoreCategory[],
    spendCategories: SpendCategory[]
}

interface RetailCentresVisibility {
    showRetailCentres: boolean,
    showNonRetailCentres: boolean
}

interface LocationState {
    isLoading: boolean,
    hasErrors: boolean,
    clientRegistration?: ClientRegistration,
    directCompetitorNames: string[],
    salesReferenceDate: DateTime,
    staticRetailCentres: Map<number, StaticRetailCentre>,
    retailCentres: RetailCentre[],
    retailCentresVisibility: RetailCentresVisibility,
    showExistingStores: boolean,
    stores: Store[],
    storeCategories: StoreCategory[],
    spendCategories: SpendCategory[],
    comparatorStores: Store[],
    targetStoreCategory?: StoreCategory,
    targetSpendCategories: SpendCategory[],
    target?: Target,
    comparatorsByChapter?: ComparatorsByChapter,
    pinnedLocation?: PinnedLocation,
    savedLocationDetails?: SavedLocationDetails,
    currentChapter: LocationChapter,
    isInsightExpanded: boolean,
    viewport?: ViewportProps,
    alignmentScoreField: ScoreField,
    catchmentCustomerProfiles: DataWrapper<CatchmentCustomerProfile[]>
    catchmentPopulation: DataWrapper<CatchmentPopulation[]>
    locationBackdropCount: number
}

const initialState: LocationState = {
    isLoading: false,
    hasErrors: false,
    clientRegistration: undefined,
    directCompetitorNames: [],
    salesReferenceDate: DateTime.fromMillis(0, { zone: "utc" }),
    staticRetailCentres: new Map<number, StaticRetailCentre>(),
    retailCentres: [],
    retailCentresVisibility: {
        showRetailCentres: true,
        showNonRetailCentres: true,
    },
    showExistingStores: true,
    stores: [],
    storeCategories: [],
    spendCategories: [],
    comparatorStores: [],
    targetStoreCategory: undefined,
    targetSpendCategories: [],
    target: undefined,
    comparatorsByChapter: undefined,
    pinnedLocation: undefined,
    savedLocationDetails: undefined,
    currentChapter: LocationChapter.Overview,
    isInsightExpanded: false,
    viewport: undefined,
    alignmentScoreField: ScoreField.Overall,
    catchmentCustomerProfiles: { isLoading: false, hasErrors: false, data: [] },
    catchmentPopulation: { isLoading: false, hasErrors: false, data: [] },
    locationBackdropCount: 0
};

const locationSlice = createSlice({
    name: "customer/tools/location",
    initialState,
    reducers: {
        clearClientRegistration: (state) => {
            state.clientRegistration = initialState.clientRegistration;
        },
        clearSalesReferenceDate: (state) => {
            state.salesReferenceDate = initialState.salesReferenceDate;
        },
        clearStaticRetailCentres: (state) => {
            state.staticRetailCentres = initialState.staticRetailCentres;
        },
        clearRetailCentres: (state) => {
            state.retailCentres = initialState.retailCentres;
        },
        toggleShowRetailCentres: (state) => {
            state.retailCentresVisibility.showRetailCentres = !state.retailCentresVisibility.showRetailCentres;
        },
        toggleShowNonRetailCentres: (state) => {
            state.retailCentresVisibility.showNonRetailCentres = !state.retailCentresVisibility.showNonRetailCentres;
        },
        toggleShowExistingStores: (state) => {
            state.showExistingStores = !state.showExistingStores;
        },
        clearRetailCentresVisibility: (state) => {
            state.retailCentresVisibility = initialState.retailCentresVisibility;
        },
        clearStores: (state) => {
            state.stores = initialState.stores;
        },
        clearStoreCategories: (state) => {
            state.storeCategories = initialState.storeCategories;
        },
        clearSpendCategories: (state) => {
            state.spendCategories = initialState.spendCategories;
        },
        chooseComparatorStoresTargetStoreCategoryTargetSpendCategoriesTargetAndComparatorsByChapter: (state, action: PayloadAction<{
            comparatorStores: Store[],
            targetStoreCategory: StoreCategory,
            targetSpendCategories: SpendCategory[],
            target: Target,
            comparatorsByChapter: ComparatorsByChapter
        }>) => {
            state.comparatorStores = [...action.payload.comparatorStores];
            state.targetStoreCategory = action.payload.targetStoreCategory;
            state.targetSpendCategories = action.payload.targetSpendCategories;
            state.target = action.payload.target;
            state.comparatorsByChapter = action.payload.comparatorsByChapter;
        },
        clearComparatorStoresTargetStoreCategoryTargetSpendCategoriesTargetAndComparatorsByChapter: (state) => {
            state.comparatorStores = initialState.comparatorStores;
            state.targetStoreCategory = initialState.targetStoreCategory;
            state.targetSpendCategories = initialState.targetSpendCategories;
            state.target = initialState.target;
            state.comparatorsByChapter = initialState.comparatorsByChapter;
        },
        choosePinnedLocation: (state, action: PayloadAction<PinnedLocation>) => {
            state.pinnedLocation = action.payload;
        },
        clearPinnedLocation: (state) => {
            state.pinnedLocation = initialState.pinnedLocation;
        },
        setSavedLocationDetails: (state, action: PayloadAction<SavedLocationDetails>) => {
            state.savedLocationDetails = action.payload;
        },
        clearSavedLocationDetails: (state) => {
            state.savedLocationDetails = initialState.savedLocationDetails;
        },
        setCurrentChapter: (state, action: PayloadAction<LocationChapter>) => {
            state.currentChapter = action.payload;
        },
        resetCurrentChapter: (state) => {
            state.currentChapter = initialState.currentChapter;
        },
        toggleIsInsightExpanded: (state) => {
            state.isInsightExpanded = !state.isInsightExpanded;
        },
        resetIsInsightExpanded: (state) => {
            state.isInsightExpanded = initialState.isInsightExpanded;
        },
        setViewport: (state, action: PayloadAction<ViewportProps>) => {
            state.viewport = action.payload;
        },
        clearViewport: (state) => {
            state.viewport = initialState.viewport;
        },
        setAlignmentScoreField: (state, action: PayloadAction<ScoreField>) => {
            state.alignmentScoreField = action.payload;
        },
        resetAlignmentScoreField: (state) => {
            state.alignmentScoreField = initialState.alignmentScoreField;
        },
        clearCatchmentCustomerProfiles: (state) => {
            state.catchmentCustomerProfiles = initialState.catchmentCustomerProfiles;
        },
        clearCatchmentPopulation: (state) => {
            state.catchmentPopulation = initialState.catchmentPopulation;
        },
        locationBackdropOn: (state) => {
            state.locationBackdropCount += 1;
        },
        locationBackdropOff: (state) => {
            state.locationBackdropCount -= 1;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadLocation.pending, (state: LocationState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.clientRegistration = initialState.clientRegistration;
            state.directCompetitorNames = initialState.directCompetitorNames;
            state.salesReferenceDate = initialState.salesReferenceDate;
            state.staticRetailCentres = initialState.staticRetailCentres;
            state.retailCentresVisibility = initialState.retailCentresVisibility;
            state.stores = initialState.stores;
            state.storeCategories = initialState.storeCategories;
            state.spendCategories = initialState.spendCategories;
            state.comparatorStores = initialState.comparatorStores;
            state.targetStoreCategory = initialState.targetStoreCategory;
            state.target = initialState.target;
            state.pinnedLocation = initialState.pinnedLocation;
            state.savedLocationDetails = initialState.savedLocationDetails;
        });
        builder.addCase(loadLocation.rejected, (state: LocationState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.clientRegistration = initialState.clientRegistration;
            state.directCompetitorNames = initialState.directCompetitorNames;
            state.salesReferenceDate = initialState.salesReferenceDate;
            state.staticRetailCentres = initialState.staticRetailCentres;
            state.retailCentresVisibility = initialState.retailCentresVisibility;
            state.stores = initialState.stores;
            state.storeCategories = initialState.storeCategories;
            state.spendCategories = initialState.spendCategories;
            state.comparatorStores = initialState.comparatorStores;
            state.targetStoreCategory = initialState.targetStoreCategory;
            state.target = initialState.target;
            state.pinnedLocation = initialState.pinnedLocation;
            state.savedLocationDetails = initialState.savedLocationDetails;
        });
        builder.addCase(loadLocation.fulfilled, (state: LocationState, action: PayloadAction<LoadLocationResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.clientRegistration = action.payload.clientRegistration;
            state.directCompetitorNames = action.payload.directCompetitorNames;
            state.salesReferenceDate = action.payload.salesReferenceDate;
            state.staticRetailCentres = action.payload.staticRetailCentres;
            state.stores = action.payload.stores;
            state.storeCategories = action.payload.storeCategories;
            state.spendCategories = action.payload.spendCategories;
        });
        builder.addCase(loadAllRetailCentres.pending, (state: LocationState) => {
            state.retailCentres = initialState.retailCentres;
        });
        builder.addCase(loadAllRetailCentres.rejected, (state: LocationState) => {
            state.retailCentres = initialState.retailCentres;
        });
        builder.addCase(loadAllRetailCentres.fulfilled, (state: LocationState, action: PayloadAction<RetailCentre[]>) => {
            state.retailCentres = action.payload;
        });
        builder.addCase(loadCatchmentCustomerProfiles.pending, (state: LocationState) => {
            state.catchmentCustomerProfiles.isLoading = true;
            state.catchmentCustomerProfiles.hasErrors = false;
        });
        builder.addCase(loadCatchmentCustomerProfiles.rejected, (state: LocationState) => {
            state.catchmentCustomerProfiles.isLoading = false;
            state.catchmentCustomerProfiles.hasErrors = true;
        });
        builder.addCase(loadCatchmentCustomerProfiles.fulfilled, (state: LocationState, action: PayloadAction<CatchmentCustomerProfile[]>) => {
            state.catchmentCustomerProfiles.data = action.payload;
            state.catchmentCustomerProfiles.isLoading = false;
            state.catchmentCustomerProfiles.hasErrors = false;
        });
        builder.addCase(loadCatchmentPopulation.pending, (state: LocationState) => {
            state.catchmentPopulation.isLoading = true;
            state.catchmentPopulation.hasErrors = false;
        });
        builder.addCase(loadCatchmentPopulation.rejected, (state: LocationState) => {
            state.catchmentPopulation.isLoading = false;
            state.catchmentPopulation.hasErrors = true;
        });
        builder.addCase(loadCatchmentPopulation.fulfilled, (state: LocationState, action: PayloadAction<CatchmentPopulation[]>) => {
            state.catchmentPopulation.data = action.payload;
            state.catchmentPopulation.isLoading = false;
            state.catchmentPopulation.hasErrors = false;
        });
    }
});

export const {
    toggleShowRetailCentres,
    toggleShowNonRetailCentres,
    toggleShowExistingStores,
    chooseComparatorStoresTargetStoreCategoryTargetSpendCategoriesTargetAndComparatorsByChapter,
    choosePinnedLocation,
    clearPinnedLocation,
    setSavedLocationDetails,
    clearSavedLocationDetails,
    toggleIsInsightExpanded,
    setCurrentChapter,
    setViewport,
    setAlignmentScoreField,
    locationBackdropOn,
    locationBackdropOff
} = locationSlice.actions;

export const loadLocation = createAppAsyncThunk(
    "customer/tools/location/loadLocation",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            const state = thunkAPI.getState();
            const featureFlags = selectFeatureFlags(state);
            const enableSpendNew = featureFlags.enableCustomerToolsLocationSpendNew;

            await thunkAPI.dispatch(setupCube());
            const clientRegistrationPromise = thunkAPI.dispatch(loadClientRegistration());
            const directCompetitorNamesPromise = thunkAPI.dispatch(loadDirectCompetitorNames());
            const salesReferenceDatePromise = thunkAPI.dispatch(loadSalesReferenceDate());
            const staticRetailCentresPromise = thunkAPI.dispatch(loadStaticRetailCentres());
            const spendCategoriesPromise = thunkAPI.dispatch(loadSpendCategories());
            const results = await Promise.all([
                clientRegistrationPromise,
                directCompetitorNamesPromise,
                salesReferenceDatePromise,
                staticRetailCentresPromise,
                spendCategoriesPromise
            ]);
            const clientRegistration = results[0];
            const directCompetitorNames = results[1];
            const salesReferenceDate = results[2];
            const staticRetailCentres = results[3];
            const spendCategories = results[4];
            const catchmentAccountId = clientRegistration.accountId;
            const spendCategoriesInPortfolio = spendCategories.filter(category => category.isInPortfolio);
            const stores = await thunkAPI.dispatch(loadStores(salesReferenceDate, staticRetailCentres, spendCategoriesInPortfolio, enableSpendNew, catchmentAccountId));
            const storeCategories = await thunkAPI.dispatch(loadStoreCategories(clientRegistration.primaryStoreCategoryId, stores));
            const loadLocationResponse: LoadLocationResponse = {
                clientRegistration,
                directCompetitorNames,
                salesReferenceDate,
                staticRetailCentres,
                stores,
                storeCategories,
                spendCategories
            };
            return loadLocationResponse;
        } catch (error) {
            thunkAPI.dispatch(notifyError("Error loading Location."));
            return thunkAPI.rejectWithValue(null);
        } finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearLocation = (): AppThunk => async (dispatch) => {
    dispatch(locationSlice.actions.clearClientRegistration());
    dispatch(locationSlice.actions.clearSalesReferenceDate());
    dispatch(locationSlice.actions.clearStaticRetailCentres());
    dispatch(locationSlice.actions.clearRetailCentres());
    dispatch(locationSlice.actions.clearRetailCentresVisibility());
    dispatch(locationSlice.actions.clearStores());
    dispatch(locationSlice.actions.clearStoreCategories());
    dispatch(locationSlice.actions.clearSpendCategories());
    dispatch(locationSlice.actions.clearComparatorStoresTargetStoreCategoryTargetSpendCategoriesTargetAndComparatorsByChapter());
    dispatch(locationSlice.actions.clearPinnedLocation());
    dispatch(locationSlice.actions.clearSavedLocationDetails());
    dispatch(locationSlice.actions.resetCurrentChapter());
    dispatch(locationSlice.actions.resetIsInsightExpanded());
    dispatch(locationSlice.actions.clearViewport());
    dispatch(locationSlice.actions.resetAlignmentScoreField());
    dispatch(clearInsights());
    dispatch(clearSavedLocations());
};

export const loadInsights = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const catchmentAccountIds = selectCatchmentAccountIds(state);
        const selectedRetailCentreId = selectPinnedLocation(state)?.retailCentre.id;
        const targetStoreCategoryId = selectTargetStoreCategory(state)?.id;
        const demographicsComparator = selectComparatorsByChapter(state)?.demographics;
        const spendComparator = selectComparatorsByChapter(state)?.spend;

        const featureFlags = selectFeatureFlags(state);
        const enableSpendNew = featureFlags.enableCustomerToolsLocationSpendNew;

        await dispatch(setupCube());
        dispatch(loadCatchmentCustomerProfiles({ scenarioCatchmentAccountId: catchmentAccountIds.scenario, selectedRetailCentreId, targetStoreCategoryId }));
        dispatch(loadCatchmentPopulation({ catchmentAccountIds, selectedRetailCentreId, demographicsComparator, spendComparator, targetStoreCategoryId }));
        dispatch(loadAreaHealth());
        dispatch(loadDemographics());
        dispatch(loadFootfall());
        dispatch(loadOverview());
        enableSpendNew ? dispatch(loadSpendNew()) : dispatch(loadSpend());
        dispatch(loadCompetition());
        dispatch(loadCannibalisation());
    } catch (error) {
        dispatch(logError("Error loading Insights.", error));
    }
};

export const clearInsights = (): AppThunk => (dispatch) => {
    dispatch(clearAreaHealth());
    dispatch(clearDemographics());
    dispatch(clearFootfall());
    dispatch(clearOverview());
    dispatch(clearSpend());
    dispatch(clearSpendNew());
    dispatch(clearCompetition());
    dispatch(clearCannibalisation());
    dispatch(locationSlice.actions.clearCatchmentCustomerProfiles());
    dispatch(locationSlice.actions.clearCatchmentPopulation());
    dispatch(locationSlice.actions.resetCurrentChapter());
    dispatch(locationSlice.actions.resetAlignmentScoreField());
};

export const loadAddress = (latitude: number, longitude: number): AppThunk<Promise<string>> => async (dispatch) => {
    const url = `/customer/tools/location/address?latitude=${latitude}&longitude=${longitude}`;
    const response = await dispatch(apiGet(url, false));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            return response.data.address;
        }
        default: {
            return "Unknown address";
        }
    }
};

export const loadAllRetailCentres = createAppAsyncThunk(
    "customer/tools/location/loadAllRetailCentres",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(locationBackdropOn());
        try {
            const state = thunkAPI.getState();
            const staticRetailCentres = selectStaticRetailCentres(state);
            const targetStoreCategory = selectTargetStoreCategory(state);
            const targetSpendCategories = selectTargetSpendCategories(state);
            const catchmentAccountIds = selectCatchmentAccountIds(state);

            const featureFlags = selectFeatureFlags(state);
            const enableSpendNew = featureFlags.enableCustomerToolsLocationSpendNew;

            await thunkAPI.dispatch(setupCube());
            return await thunkAPI.dispatch(loadRetailCentres(staticRetailCentres, targetStoreCategory, targetSpendCategories, enableSpendNew, catchmentAccountIds.scenario));
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading AllRetailCentres."));
            return thunkAPI.rejectWithValue(null);
        } 
        finally {
            thunkAPI.dispatch(locationBackdropOff());
        }
    }
);

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.root.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.root.hasErrors;
};

export const selectClientRegistration = (state: RootState) => {
    return state.customer.tools.location.root.clientRegistration;
};

export const selectDirectCompetitorNames = (state: RootState) => {
    return state.customer.tools.location.root.directCompetitorNames;
};

export const selectSalesReferenceDate = (state: RootState) => {
    return state.customer.tools.location.root.salesReferenceDate;
};

const selectStaticRetailCentres = (state: RootState) => {
    return state.customer.tools.location.root.staticRetailCentres;
};

export const selectRetailCentres = (state: RootState) => {
    return state.customer.tools.location.root.retailCentres;
};

export const selectRetailCentresVisibility = (state: RootState): RetailCentresVisibility => {
    return state.customer.tools.location.root.retailCentresVisibility;
};

export const selectShowExistingStores = (state: RootState) => {
    return state.customer.tools.location.root.showExistingStores;
};

export const selectStores = (state: RootState) => {
    return state.customer.tools.location.root.stores;
};

export const selectStoreCategories = (state: RootState) => {
    return state.customer.tools.location.root.storeCategories;
};

export const selectSpendCategories = (state: RootState) => {
    return state.customer.tools.location.root.spendCategories;
};

export const selectComparatorStores = (state: RootState) => {
    return state.customer.tools.location.root.comparatorStores;
};

export const selectTargetStoreCategory = (state: RootState) => {
    return state.customer.tools.location.root.targetStoreCategory;
};

export const selectTargetSpendCategories = (state: RootState) => {
    return state.customer.tools.location.root.targetSpendCategories;
};

export const selectTarget = (state: RootState) => {
    return state.customer.tools.location.root.target;
};

export const selectComparatorsByChapter = (state: RootState) => {
    return state.customer.tools.location.root.comparatorsByChapter;
};

export const selectPinnedLocation = (state: RootState) => {
    return state.customer.tools.location.root.pinnedLocation;
};

export const selectSavedLocationDetails = (state: RootState) => {
    return state.customer.tools.location.root.savedLocationDetails;
};

export const selectCurrentChapter = (state: RootState) => {
    return state.customer.tools.location.root.currentChapter;
};

export const selectIsInsightExpanded = (state: RootState) => {
    return state.customer.tools.location.root.isInsightExpanded;
};

export const selectViewport = (state: RootState) => {
    return state.customer.tools.location.root.viewport;
};

export const selectAlignmentScoreField = (state: RootState) => {
    return state.customer.tools.location.root.alignmentScoreField;
};

export const selectCatchmentCustomerProfiles = (state: RootState) => {
    return state.customer.tools.location.root.catchmentCustomerProfiles;
};

export const selectCatchmentPopulation = (state: RootState) => {
    return state.customer.tools.location.root.catchmentPopulation;
};

export const selectIsLocationBackdropOn = (state: RootState): boolean => {
    return state.customer.tools.location.root.locationBackdropCount > 0;
};

export const selectIsRetailCentresVisibilityModified = createSelector(
    selectRetailCentresVisibility,
    (retailCentresVisibility) => {
        return retailCentresVisibility.showRetailCentres !== initialState.retailCentresVisibility.showRetailCentres
            || retailCentresVisibility.showNonRetailCentres !== initialState.retailCentresVisibility.showNonRetailCentres;
    }
);

export const selectShowExistingStoresModified = createSelector(
    selectShowExistingStores,
    (showExistingStores) => {
        return showExistingStores !== initialState.showExistingStores;
    }
);

const selectRetailCentresWithScores = createSelector(
    selectRetailCentres,
    selectTarget,
    (retailCentres, target) => {
        if (retailCentres.length === 0 || !target) {
            return [];
        }

        const calculateAlignmentScore = (retailCentreCentiles: number[], targetCentiles: number[]) => {
            if (retailCentreCentiles.length === 0 || targetCentiles.length === 0 || retailCentreCentiles.length !== targetCentiles.length) {
                return 0;
            }
            const distanceToTarget = Number(distance(retailCentreCentiles, targetCentiles));
            const maxDistance = Number(sqrt(retailCentreCentiles.length * (99 ** 2)));
            return 10 * (1 - (distanceToTarget / maxDistance));
        };

        const alignDemographics = target.useDemographics ? 1 : 0;
        const alignSpend = target.useSpend ? 1 : 0;
        const alignAreaHealth = target.useAreaHealth ? 1 : 0;
        const alignFootfall = target.useFootfall ? 1 : 0;
        const alignmentChaptersCount = alignDemographics + alignSpend + alignAreaHealth + alignFootfall;

        const retailCentresWithScores: RetailCentreWithScores[] = [];
        for (const retailCentre of retailCentres) {
            const demographicsScore = alignDemographics
                ? calculateAlignmentScore(
                    [retailCentre.affluenceCentile, retailCentre.ageCentile, retailCentre.childrenCentile, retailCentre.diversityCentile, retailCentre.urbanicityCentile],
                    [target.affluence, target.age, target.children, target.diversity, target.urbanicity])
                : 0;
            const spendScore = alignSpend
                ? calculateAlignmentScore([retailCentre.spendCentile], [target.spend])
                : 0;
            const areaHealthScore = (retailCentre.emptyAreaHealth || !alignAreaHealth)
                ? 0
                : calculateAlignmentScore([retailCentre.areaHealthCentile], [target.areaHealth]);
            const footfallScore = alignFootfall
                ? calculateAlignmentScore([retailCentre.footfallCentile], [target.footfall])
                : 0;

            const scoresSum = sum(
                (demographicsScore * alignDemographics),
                (spendScore * alignSpend),
                (areaHealthScore * alignAreaHealth),
                (footfallScore * alignFootfall));

            const overallScore = alignmentChaptersCount === 0
                ? 0
                : scoresSum / alignmentChaptersCount;

            const retailCentreWithScores = new RetailCentreWithScores(retailCentre, overallScore, demographicsScore, spendScore, areaHealthScore, footfallScore);
            retailCentresWithScores.push(retailCentreWithScores);
        }
        return retailCentresWithScores;
    }
);

const selectFilteredRetailCentresWithScores = createSelector(
    selectRetailCentresWithScores,
    selectRetailCentresVisibility,
    (retailCentres, retailCentresVisibility) => {
        return retailCentres.filter(retailCentre =>
            (retailCentre.isInRetailCentre && retailCentresVisibility.showRetailCentres)
            || (!retailCentre.isInRetailCentre && retailCentresVisibility.showNonRetailCentres)
        );
    }
);

export const selectRetailCentresWithScoreGroups = createSelector(
    selectFilteredRetailCentresWithScores,
    selectAlignmentScoreField,
    (retailCentres, alignmentScoreField) => {
        return retailCentres.map(retailCentre => {
            return {
                ...retailCentre,
                scoreGroup: retailCentre.getRagScore(alignmentScoreField)
            };
        });
    }
);

export const selectLocalAuthorities = createSelector(
    selectRetailCentresWithScoreGroups,
    (retailCentres) => {
        const localAuthorities = _(retailCentres)
            .groupBy(rc => rc.localAuthorityCode)
            .map((group, key) => ({
                code: key,
                strongRetailCentreCount: _.size(group.filter(rc => rc.scoreGroup >= 4))
            }))
            .value();
        const scores = localAuthorities.map(localAuthority => localAuthority.strongRetailCentreCount);
        const scoreGroupThresholds = mathUtils.percentileThresholds(scores, 6);
        return localAuthorities.map(localAuthority => {
            let scoreGroup = 0;
            for (let i = 0; i < scoreGroupThresholds.length; i++) {
                if (localAuthority.strongRetailCentreCount >= scoreGroupThresholds[i]) {
                    scoreGroup = i;
                }
            }
            return {
                ...localAuthority,
                scoreGroup
            };
        });
    }
);

export const selectIsInsightVisible = createSelector(
    selectPinnedLocation,
    (pinnedLocation) => {
        return pinnedLocation !== undefined;
    }
);

export const selectUseMLCatchments = createSelector(
    selectClientRegistration,
    selectStoreCategories,
    selectTargetStoreCategory,
    selectCandidateTargetStoreCategory,
    (clientRegistration, storeCategories, targetStoreCategory, candidateTargetStoreCategory) => {
        if (!candidateTargetStoreCategory && !targetStoreCategory) {
            return false;
        }
        const availableTargetCategoryId = candidateTargetStoreCategory?.id
            ? candidateTargetStoreCategory.id
            : targetStoreCategory!.id;

        const clientsStoreCategoryIds = storeCategories
            .filter(category => category.isInPortfolio)
            .map(category => category.id);
        
        return !!clientRegistration?.hasCustomCatchments && clientsStoreCategoryIds.includes(availableTargetCategoryId);
    }
);

export interface CatchmentAccountIds {
    baseline: string,
    scenario: string
}

export const selectCatchmentAccountIds = createSelector(
    selectClientRegistration,
    selectStoreCategories,
    selectTargetStoreCategory,
    selectCandidateTargetStoreCategory,
    (clientRegistration, storeCategories, targetStoreCategory, candidateTargetStoreCategory) => {
        const defaultClientId = "00000000-0000-0000-0000-000000000000";
        if (!candidateTargetStoreCategory && !targetStoreCategory) {
            return {
                baseline: defaultClientId,
                scenario: defaultClientId
            };
        }
        const availableTargetCategoryId = candidateTargetStoreCategory?.id
            ? candidateTargetStoreCategory.id
            : targetStoreCategory!.id;

        const clientsStoreCategoryIds = storeCategories
            .filter(category => category.isInPortfolio)
            .map(category => category.id);
        const isClientCategory = clientsStoreCategoryIds.includes(availableTargetCategoryId);

        return {
            baseline: isClientCategory ? clientRegistration?.accountId ?? defaultClientId : defaultClientId,
            scenario: (isClientCategory && clientRegistration?.hasCustomCatchments) ? clientRegistration.accountId : defaultClientId
        };
    }
);

export default locationSlice;
