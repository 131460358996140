import React from "react";

import { Box, Container, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { SubchapterProps } from "./Subchapter";

const StyledPaper = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Paper);

interface ChapterProps {
    title: string,
    subtitle: string | React.ReactElement
    dataCy: string,
    children: React.ReactElement<SubchapterProps> | React.ReactElement<SubchapterProps>[]
}

const Chapter: React.FC<ChapterProps> = (props) => {
    const { title, subtitle, dataCy } = props;

    return (
        <Container maxWidth={false}>
            <br />
            <Box paddingBottom={8} data-cy={dataCy}>
                <Box paddingBottom={2} data-cy="header">
                    <Container>
                        <Box paddingBottom={3}>
                            <Typography variant="h3" component="div" data-cy="title">
                                {title}
                            </Typography>
                        </Box>
                        <Box paddingBottom={3}>
                            <Typography variant="subtitle1" component="div" data-cy="subtitle">
                                {subtitle}
                            </Typography>
                        </Box>
                    </Container>
                </Box>
                <StyledPaper elevation={0} data-cy="subchapters">
                    <Container>
                        <Box paddingTop={4}>
                            {props.children}
                        </Box>
                    </Container>
                </StyledPaper>
            </Box>
        </Container>
    );
};

export default Chapter;
