import { Query, ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { KPMGSpendCategory } from "./kpmgSpendCategory";
import { Store } from "./store";

export class CatchmentSpend {
    public readonly storeId: string;
    public readonly catchmentSpend: number;

    constructor(
        storeId: string,
        catchmentSpend: number
    ) {
        this.storeId = storeId;
        this.catchmentSpend = catchmentSpend;
    }
}

export const loadCatchmentSpend = (stores: Store[], clientId: string, spendCategories: KPMGSpendCategory[]): AppThunk<Promise<CatchmentSpend[]>> => async (dispatch) => {
    try {
        const retailCentreIDsByStoreCategoryID = new Map<number, number[]>();
        stores.forEach(store => {
            const retailCentreIDs = retailCentreIDsByStoreCategoryID.get(store.storeCategoryID) ?? [];
            retailCentreIDs.push(store.retailCentreID);
            retailCentreIDsByStoreCategoryID.set(store.storeCategoryID, retailCentreIDs);
        });
        const orFilterClause = { or: [] };
        retailCentreIDsByStoreCategoryID.forEach((retailCentreIDs, storeCategoryID) => {
            const andFilterClause = {
                and: [{
                    member: "LocationBenchmarkSpendMetrics.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIDs.map(String)
                }, {
                    member: "LocationBenchmarkSpendMetrics.StoreCategory_ID",
                    operator: "equals",
                    values: [String(storeCategoryID)]
                }]
            };
            // @ts-ignore
            orFilterClause.or.push(andFilterClause);
        });

        const spendCategoryIds = spendCategories.map(item => String(item.id));
        const query: Query = {
            measures: [
                "LocationBenchmarkSpendMetrics.SumWeightedSpend"
            ],
            dimensions: [
                "LocationBenchmarkSpendMetrics.RetailCentreID",
                "LocationBenchmarkSpendMetrics.StoreCategory_ID",
            ],
            filters: [{
                member: "LocationBenchmarkSpendMetrics.Client_ID",
                operator: "equals",
                values: [clientId]
            }, {
                member: "LocationBenchmarkSpendMetrics.SpendCategory_ID",
                operator: "equals",
                values: spendCategoryIds
            }, orFilterClause]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        return stores.map(store => {
            const catchmentSpendRow = rawData.find(row => row["LocationBenchmarkSpendMetrics.RetailCentreID"] === store.retailCentreID
                && row["LocationBenchmarkSpendMetrics.StoreCategory_ID"] === store.storeCategoryID);
            return new CatchmentSpend(
                store.id,
                catchmentSpendRow ? catchmentSpendRow["LocationBenchmarkSpendMetrics.SumWeightedSpend"] : null
            );
        });
    } catch (error) {
        dispatch(logError("Error loading CatchmentSpend.", error));
        throw error;
    }
};
