import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Divider, FormControlLabel, FormGroup, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import { operations, selectors } from "modules/customer/account/users";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";

const StyledFormControlLabel = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(-0.5),
        paddingBottom: theme.spacing(1)
    }
}))(FormControlLabel);

const StyledSwitch = withStyles(theme => ({
    switchBase: {
        "&$checked": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
        },
        "&$checked + $track": {
            // @ts-ignore
            backgroundColor: theme.palette.tertiary.main
        }
    },
    checked: {},
    track: {}
}))(Switch);

const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: theme.palette.quaternary.main
    },
    checkIcon: {
        color: theme.palette.tertiary.main
    }
}));

const AddUser = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const featureFlags = useSelector(selectFeatureFlags);
    const licenses = useSelector(state => selectors.licenses(state));
    const user = useSelector(state => selectors.user(state));
    const recentlyAddedUsers = useSelector(state => selectors.recentlyAddedUsers(state));
    const addUserVisibility = useSelector(state => selectors.addUserVisibility(state));
    const show = addUserVisibility.isVisible;

    const handleAddUserClick = () => {
        dispatch(operations.showAddUser());
    };

    const handleSubmitClick = () => {
        dispatch(operations.addUser());
    };

    const handleCancelClick = () => {
        dispatch(operations.hideAddUser());
        dispatch(operations.clearUser());
    };

    const handleFirstNameChange = (event) => {
        const firstName = event.target.value;
        const newUser = { ...user, firstName, errors: { ...user.errors, firstName: "" } };
        dispatch(operations.setUser(newUser));
    };

    const handleLastNameChange = (event) => {
        const lastName = event.target.value;
        const newUser = { ...user, lastName, errors: { ...user.errors, lastName: "" } };
        dispatch(operations.setUser(newUser));
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        const newUser = { ...user, email, errors: { ...user.errors, email: "" } };
        dispatch(operations.setUser(newUser));
    };

    const handleRoleChange = (event) => {
        const role = event.target.value;
        const newUser = { ...user, role, errors: { ...user.errors, role: "" } };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessPerformanceChange = () => {
        const newUser = { ...user, canAccessPerformance: !user.canAccessPerformance };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessPortfolioChange = () => {
        const newUser = { ...user, canAccessPortfolio: !user.canAccessPortfolio };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessCostChange = () => {
        const newUser = { ...user, canAccessCost: !user.CanAccessCost };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessLocationChange = () => {
        const newUser = { ...user, canAccessLocation: !user.canAccessLocation };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessReportChange = () => {
        const newUser = { ...user, canAccessReport: !user.canAccessReport };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessProductChange = () => {
        const newUser = { ...user, canAccessProduct: !user.canAccessProduct };
        dispatch(operations.setUser(newUser));
    };

    return (
        <>
            <Button
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddIcon />}
                disabled={licenses.remaining === 0}
                onClick={handleAddUserClick}
                disableElevation
                fullWidth
            >
                Add user
            </Button>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h5" component="div" gutterBottom>Add user</Typography>
                        <Typography variant="subtitle1" component="div">Give your teammates access to Dash</Typography>
                    </>
                }
                content={
                    <>
                        <TextField
                            label="First name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={user.firstName}
                            onChange={handleFirstNameChange}
                            error={!!user.errors.firstName}
                            helperText={user.errors.firstName}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <TextField
                            label="Last name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={user.lastName}
                            onChange={handleLastNameChange}
                            error={!!user.errors.lastName}
                            helperText={user.errors.lastName}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <TextField
                            label="Email"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={user.email}
                            onChange={handleEmailChange}
                            error={!!user.errors.email}
                            helperText={user.errors.email}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <TextField
                            label="Role"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={user.role}
                            onChange={handleRoleChange}
                            error={!!user.errors.role}
                            helperText={user.errors.role}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        >
                            <MenuItem value="Admin">Admin</MenuItem>
                            <MenuItem value="Editor">Editor</MenuItem>
                            <MenuItem value="Viewer">Viewer</MenuItem>
                        </TextField>
                        <br /><br />
                        <Typography variant="subtitle1" component="div" gutterBottom>Select modules user can access</Typography>
                        <FormGroup>
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={user.canAccessPerformance}
                                        onChange={handleCanAccessPerformanceChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle1" component="div">
                                        Performance
                                    </Typography>
                                }
                            />
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={user.canAccessPortfolio}
                                        onChange={handleCanAccessPortfolioChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle1" component="div">
                                        Portfolio
                                    </Typography>
                                }
                            />
                            {featureFlags.enableCustomerInsightsCost && (
                                <StyledFormControlLabel
                                    control={
                                        <StyledSwitch
                                            size="small"
                                            color="default"
                                            checked={user.canAccessCost}
                                            onChange={handleCanAccessCostChange}
                                        />
                                    }
                                    label={
                                        <Typography variant="subtitle1" component="div">
                                            Cost
                                        </Typography>
                                    }
                                />
                            )}
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={user.canAccessLocation}
                                        onChange={handleCanAccessLocationChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle1" component="div">
                                        Location
                                    </Typography>
                                }
                            />
                            {featureFlags.enableCustomerToolsReport && (
                                <StyledFormControlLabel
                                    control={
                                        <StyledSwitch
                                            size="small"
                                            color="default"
                                            checked={user.canAccessReport}
                                            onChange={handleCanAccessReportChange}
                                        />
                                    }
                                    label={
                                        <Typography variant="subtitle1" component="div">
                                            Report
                                        </Typography>
                                    }
                                />
                            )}
                            {featureFlags.enableCustomerToolsProduct && (
                                <StyledFormControlLabel
                                    control={
                                        <StyledSwitch
                                            size="small"
                                            color="default"
                                            checked={user.canAccessProduct}
                                            onChange={handleCanAccessProductChange}
                                        />
                                    }
                                    label={
                                        <Typography variant="subtitle1" component="div">
                                            Product
                                        </Typography>
                                    }
                                />
                            )}
                        </FormGroup>
                        {recentlyAddedUsers.length > 0 && (
                            <Box paddingTop={2}>
                                <Divider />
                            </Box>
                        )}
                        {recentlyAddedUsers.map((user, index) =>
                            <Box key={index} display="flex" alignItems="center" paddingTop={1}>
                                <CheckCircleOutlineIcon fontSize="small" className={classes.checkIcon} />
                                &nbsp;&nbsp;
                                <Typography variant="subtitle2" component="div">{user.email}</Typography>
                                &nbsp;&nbsp;&nbsp;
                                <Box color="text.disabled">
                                    <Typography variant="subtitle2" color="inherit" component="div">{user.role}</Typography>
                                </Box>
                            </Box>
                        )}
                    </>
                }
                actions={
                    <>
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            disableElevation
                            onClick={handleSubmitClick}
                        >
                            Add user
                        </Button>
                    </>
                }
            />
        </>
    );
};

export default AddUser;
