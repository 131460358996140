import React from "react";

import { Box, Typography } from "@material-ui/core";

export interface SubchapterProps {
    title: string,
    subtitle: string | React.ReactElement,
    dataCy: string,
    children?: React.ReactElement
}

const Subchapter: React.FC<SubchapterProps> = (props) => {
    const { title, subtitle, dataCy } = props;

    return (
        <Box paddingBottom={8} data-cy={dataCy}>
            <Box data-cy="header">
                <Box paddingBottom={3}>
                    <Typography variant="h5" component="div" data-cy="title">
                        {title}
                    </Typography>
                </Box>
                <Box paddingBottom={3}>
                    <Typography variant="subtitle1" component="div" data-cy="subtitle">
                        {subtitle}
                    </Typography>
                </Box>
            </Box>
            {props.children}
        </Box>
    );
};

export default Subchapter;
