import React from "react";
import { Grid } from "@material-ui/core";

import Filter from "./Filter";
import SharedPopulation from "./SharedPopulation";
import UniquePopulation from "./UniquePopulation";
import Map from "./Map";

const Cannibalisation = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Filter />
            </Grid>
            <Grid item xs={12} md={6}>
                <SharedPopulation />
            </Grid>
            <Grid item xs={12} md={6}>
                <UniquePopulation />
            </Grid>
            <Grid item xs={12}>
                <Map />
            </Grid>
        </Grid>
    );
};

export default Cannibalisation;
