import React from "react";

import { Box, Button, Container, Divider, List, ListItem, ListItemText, ListSubheader, Typography } from "@material-ui/core";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { ProductChapter, selectCurrentChapter, setCurrentChapter } from "modules/customer/tools/product/productSlice";
import { useAppDispatch, useAppSelector } from "store";

const Navigation: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const currentChapter = useAppSelector(selectCurrentChapter);

    const chooseChapter = (newChapter: ProductChapter) => {
        dispatch(setCurrentChapter(newChapter));
    };

    return (
        // @ts-ignore
        <Box bgcolor={theme.palette.background.insight} height="100vh">
            <Container maxWidth={false}>
                <Box paddingTop={1} paddingBottom={2} display="flex" alignItems="center">
                    <Typography variant="h3" component="div">
                        Product
                    </Typography>
                    <Spacer />
                    <Button
                        variant="outlined"
                        size="small"
                        color="default"
                        disableElevation
                    >
                        <SettingsRoundedIcon />
                    </Button>
                </Box>
                <Divider />
                <Box paddingTop={2} paddingBottom={2}>
                    <List disablePadding>
                        <ListSubheader disableGutters>
                            Product
                        </ListSubheader>
                        <ListItem
                            button
                            selected={currentChapter === ProductChapter.ProductOverview}
                            onClick={() => chooseChapter(ProductChapter.ProductOverview)}
                        >
                            <ListItemText primary="Product overview" />
                        </ListItem>
                        <ListItem
                            button
                            selected={currentChapter === ProductChapter.ProductOpportunities}
                            onClick={() => chooseChapter(ProductChapter.ProductOpportunities)}
                        >
                            <ListItemText primary="Product opportunities" />
                        </ListItem>
                        <ListSubheader disableGutters>
                            Store
                        </ListSubheader>
                        <ListItem
                            button
                            selected={currentChapter === ProductChapter.StoreOverview}
                            onClick={() => chooseChapter(ProductChapter.StoreOverview)}
                        >
                            <ListItemText primary="Store overview" />
                        </ListItem>
                        <ListItem
                            button
                            selected={currentChapter === ProductChapter.StoreOpportunities}
                            onClick={() => chooseChapter(ProductChapter.StoreOpportunities)}
                        >
                            <ListItemText primary="Store opportunities" />
                        </ListItem>
                        <ListSubheader disableGutters>
                            Product store fit
                        </ListSubheader>
                        <ListItem
                            button
                            selected={currentChapter === ProductChapter.ProductStoreFit}
                            onClick={() => chooseChapter(ProductChapter.ProductStoreFit)}
                        >
                            <ListItemText primary="Product store fit" />
                        </ListItem>
                    </List>
                </Box>
            </Container>
        </Box>
    );
};

export default Navigation;
