import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class YearlyCosts {
    public readonly storeId: string;
    public readonly netProfit: number;
    public readonly propertyCosts: number;
    public readonly payrollCosts: number;
    public readonly otherCosts: number;
    public readonly propertyCostsPerSquareFoot: number;
    public readonly revenuePerPoundOfPropertyCost: number;
    public readonly payrollCostsPerSquareFoot: number;
    public readonly revenuePerPoundOfPayrollCost: number;


    constructor(
        storeId: string,
        storeSquareFootage: number,
        revenue: number,
        grossProfit: number,
        propertyCosts: number,
        payrollCosts: number,
        otherCosts: number
    ) {
        this.storeId = storeId;
        this.netProfit = grossProfit - (propertyCosts + payrollCosts + otherCosts);
        this.propertyCosts = propertyCosts;
        this.payrollCosts = payrollCosts;
        this.otherCosts = otherCosts;
        this.propertyCostsPerSquareFoot = propertyCosts / storeSquareFootage;
        this.revenuePerPoundOfPropertyCost = revenue / propertyCosts;
        this.payrollCostsPerSquareFoot = payrollCosts / storeSquareFootage;
        this.revenuePerPoundOfPayrollCost = revenue / payrollCosts;
    }
}

export const loadYearlyCosts = (costReferenceDate: DateTime): AppThunk<Promise<YearlyCosts[]>> => async (dispatch) => {
    try {
        const priorTwelveMonthsToReferenceDate = costReferenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf("day");

        const costsQuery = {
            measures: ["F_Cost.SumPropertyCost", "F_Cost.SumPayrollCost", "F_Cost.SumOtherCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsToReferenceDate, costReferenceDate.endOf('day')]
            }],
            dimensions: ["D_Store.StoreNaturalID", "D_Store.Sqft"]
        };

        const salesQuery = {
            measures: ["F_Sales.SumLineValue", "F_Sales.SumContribution"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsToReferenceDate, costReferenceDate.endOf('day')]
            }],
            dimensions: ["D_Store.StoreNaturalID"]
        };

        const costsPromise = dispatch(cubeLoad(costsQuery));
        const salesPromise = dispatch(cubeLoad(salesQuery));
        const results = await Promise.all([costsPromise, salesPromise]) as unknown as ResultSet[];
        const costsRawData = results[0].rawData();
        const salesRawData = results[1].rawData();
        return costsRawData.map(costsRow => {
            const relevantSalesData = salesRawData.find(salesRow => salesRow["D_Store.StoreNaturalID"] === costsRow["D_Store.StoreNaturalID"]);
            return new YearlyCosts(
                costsRow["D_Store.StoreNaturalID"],
                costsRow["D_Store.Sqft"],
                relevantSalesData ? relevantSalesData["F_Sales.SumLineValue"] : 0,
                relevantSalesData ? relevantSalesData["F_Sales.SumContribution"] : 0,
                costsRow["F_Cost.SumPropertyCost"],
                costsRow["F_Cost.SumPayrollCost"],
                costsRow["F_Cost.SumOtherCost"]
            );
        });
    } catch (error) {
        dispatch(logError("Error loading YearlyCosts.", error));
        throw error;
    }
};
