import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";
import RolesInfo from "./RolesInfo";
import UserRole from "./UserRole";

import { selectUserInfo } from "modules/auth/authSlice";
import { operations, selectors } from "modules/customer/account/users";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

const UsersTable = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);
    const featureFlags = useSelector(selectFeatureFlags);
    const users = useSelector(state => selectors.users(state));
    const sort = useSelector(state => selectors.sort(state));

    const handleSortClick = (field) => () => {
        let direction = "asc";
        if (sort.field === field) {
            direction = sort.direction === "asc" ? "desc" : "asc";
        }
        const newSort = { ...sort, field, direction };
        dispatch(operations.setSort(newSort));
    };

    return (
        <>
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Manage your users
                </Typography>
            </Box>
            {users.length === 0 ? (
                <NoData />
            ) : (
                <StyledTableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sort.field === "name"}
                                        direction={sort.direction}
                                        onClick={handleSortClick("name")}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        <Box color="text.disabled">
                                            <Typography variant="h6" component="div">
                                                Name
                                            </Typography>
                                        </Box>
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sort.field === "email"}
                                        direction={sort.direction}
                                        onClick={handleSortClick("email")}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        <Box color="text.disabled">
                                            <Typography variant="h6" component="div">
                                                Email
                                            </Typography>
                                        </Box>
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sort.field === "role"}
                                        direction={sort.direction}
                                        onClick={handleSortClick("role")}
                                        IconComponent={ExpandMoreIcon}
                                    >
                                        <Box color="text.disabled" display="flex" alignItems="center">
                                            <Typography variant="h6" component="div">
                                                Role
                                            </Typography>
                                            &nbsp;
                                            <RolesInfo />
                                        </Box>
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Box color="text.disabled">
                                        <Typography variant="h6" component="div">
                                            Can access
                                        </Typography>
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <StyledTableCell>
                                        <Typography variant="subtitle1" component="div">
                                            {user.firstName} {user.lastName}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Typography variant="subtitle1" component="div">
                                            {user.email}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <UserRole id={user.id} role={user.role} />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Typography variant="subtitle1" component="div">
                                            {(() => {
                                                const modules = [];
                                                if (user.canAccessPerformance) {modules.push("Performance");}
                                                if (user.canAccessPortfolio) { modules.push("Portfolio"); }
                                                if (featureFlags.enableCustomerInsightsCost && user.canAccessCost) { modules.push("Cost"); }
                                                if (user.canAccessLocation) {modules.push("Location");}
                                                if (featureFlags.enableCustomerToolsReport && user.canAccessReport) {modules.push("Report");}
                                                if (featureFlags.enableCustomerToolsProduct && user.canAccessProduct) {modules.push("Product");}
                                                return modules.join(", ");
                                            })()}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {user.role !== "Owner" && user.email !== userInfo.email && (
                                            <>
                                                <EditUser id={user.id} />
                                                <DeleteUser id={user.id} />
                                            </>
                                        )}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            )}
        </>
    );
};

export default UsersTable;
