import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import { loadStores, Store } from "./store";
import { loadKPMGSpendCategories } from "./kpmgSpendCategory";
import { loadClientRegistration } from "./clientRegistration";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { CatchmentCustomerProfiles, loadCatchmentCustomerProfiles } from "./catchmentCustomerProfile";
import { SortDirection, stringSortExpression } from "utils/sortUtils";
import { GridColDef } from "@mui/x-data-grid-pro";
import numberFormatter from "utils/numberFormatter";
import { loadCostReferenceDate } from "./costsReferenceDate";
import { loadYearlyCosts, YearlyCosts } from "./yearlyCosts";
import { CatchmentSpend, loadCatchmentSpend } from "./catchmentSpend";
import { ForecastSales, loadForecastSales } from "./forecastSales";

interface LoadReportResponse {
    stores: Store[],
    forecastSales: ForecastSales[],
    yearlyCosts: YearlyCosts[],
    catchmentSpend: CatchmentSpend[],
    catchmentCustomerProfiles: CatchmentCustomerProfiles[]
}

interface ReportState {
    isLoading: boolean,
    hasErrors: boolean,
    stores: Store[],
    forecastSales: ForecastSales[],
    yearlyCosts: YearlyCosts[],
    catchmentSpend: CatchmentSpend[],
    catchmentCustomerProfiles: CatchmentCustomerProfiles[]
}

const initialState: ReportState = {
    isLoading: false,
    hasErrors: false,
    stores: [],
    forecastSales: [],
    yearlyCosts: [],
    catchmentSpend: [],
    catchmentCustomerProfiles: []
};

const reportSlice = createSlice({
    name: "customer/tools/report",
    initialState,
    reducers: {
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadReport.pending, (state: ReportState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.stores = [];
            state.forecastSales = [];
            state.yearlyCosts = [];
            state.catchmentSpend = [];
            state.catchmentCustomerProfiles = [];
        });
        builder.addCase(loadReport.rejected, (state: ReportState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.stores = [];
            state.forecastSales = [];
            state.yearlyCosts = [];
            state.catchmentSpend = [];
            state.catchmentCustomerProfiles = [];
        });
        builder.addCase(loadReport.fulfilled, (state: ReportState, action: PayloadAction<LoadReportResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.stores = action.payload.stores;
            state.forecastSales = action.payload.forecastSales;
            state.yearlyCosts = action.payload.yearlyCosts;
            state.catchmentSpend = action.payload.catchmentSpend;
            state.catchmentCustomerProfiles = action.payload.catchmentCustomerProfiles;
        });
    }
});

export const loadReport = createAppAsyncThunk(
    "customer/tools/report/loadReport",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());
            const clientRegistrationPromise = thunkAPI.dispatch(loadClientRegistration());
            const kpmgSpendCategoriesPromise = thunkAPI.dispatch(loadKPMGSpendCategories());
            const costsReferenceDatePromise = thunkAPI.dispatch(loadCostReferenceDate());
            const setupResults = await Promise.all([
                clientRegistrationPromise,
                kpmgSpendCategoriesPromise,
                costsReferenceDatePromise
            ]);
            const clientRegistration = setupResults[0];
            const kpmgSpendCategories = setupResults[1];
            const costsReferenceDate = setupResults[2];

            const storesPromise = thunkAPI.dispatch(loadStores(kpmgSpendCategories, clientRegistration.accountId));
            const forecastSalesPromise = thunkAPI.dispatch(loadForecastSales());
            const yearlyCostsPromise = thunkAPI.dispatch(loadYearlyCosts(costsReferenceDate));
            const catchmentCustomerProfilesPromise = thunkAPI.dispatch(loadCatchmentCustomerProfiles(clientRegistration.accountId));
            const results = await Promise.all([
                storesPromise,
                forecastSalesPromise,
                yearlyCostsPromise,
                catchmentCustomerProfilesPromise,
            ]);
            const stores = results[0];
            const forecastSales = results[1];
            const yearlyCosts = results[2];
            const catchmentCustomerProfiles = results[3];

            const catchmentSpend = await thunkAPI.dispatch(loadCatchmentSpend(stores, clientRegistration.accountId, kpmgSpendCategories));
            const loadReportResponse: LoadReportResponse = {
                stores,
                forecastSales,
                yearlyCosts,
                catchmentSpend,
                catchmentCustomerProfiles
            };
            return loadReportResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Report.", error));
            return thunkAPI.rejectWithValue(null);
        } finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearReport = (): AppThunk => (dispatch) => {
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.report.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.report.hasErrors;
};

export const selectStores = (state: RootState) => {
    return state.customer.tools.report.stores;
};

export const selectForecastSales = (state: RootState) => {
    return state.customer.tools.report.forecastSales;
};

export const selectYearlyCosts = (state: RootState) => {
    return state.customer.tools.report.yearlyCosts;
};

export const selectCatchmentSpend = (state: RootState) => {
    return state.customer.tools.report.catchmentSpend;
};

export const selectCatchmentCustomerProfiles = (state: RootState) => {
    return state.customer.tools.report.catchmentCustomerProfiles;
};

const arrayJoinOnKey = (arrays: any[][], joinKey: string) => {
    if (arrays.length === 0) {
        return [];
    } else if (arrays.length === 1) {
        return arrays[0];
    }

    const sortedArrays = arrays.map(array =>
        [...array].sort((a, b) => stringSortExpression(a[joinKey] ?? '', b[joinKey] ?? '', SortDirection.ASC))
    );
    const arrayIndexes = arrays.map(array => 0);

    return sortedArrays[0].map(baseRow => {
        let joinedRow = baseRow;
        for (let i = 1; i < sortedArrays.length; i++) {
            let currentArray = sortedArrays[i];
            let joiningRow = currentArray[arrayIndexes[i]];

            if (baseRow[joinKey] !== (joiningRow ? joiningRow[joinKey] : undefined)) {
                let relevantRowIndex = currentArray.findIndex(item => item[joinKey] === baseRow[joinKey]);
                joiningRow = currentArray[relevantRowIndex];
            }
            arrayIndexes[i]++;
            joinedRow = Object.assign({}, joinedRow, joiningRow);
        }
        return joinedRow;
    });
};

export const selectStoresWithAllMetrics = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectStores,
    selectForecastSales,
    selectYearlyCosts,
    selectCatchmentSpend,
    selectCatchmentCustomerProfiles,
    (isLoading, hasErrors, stores, forecastSales, yearlyCosts, catchmentSpend, catchmentCustomerProfiles) => {
        if (isLoading || hasErrors) {
            return [];
        }
        const storesWithStoreIds = stores.map(store => ({
            storeId: store.id,
            ...store
        }));
        const storesWithAllMetrics = arrayJoinOnKey([storesWithStoreIds, forecastSales, yearlyCosts, catchmentSpend, catchmentCustomerProfiles], "storeId");
        return storesWithAllMetrics.sort((a, b) => stringSortExpression(a.name, b.name, SortDirection.ASC));
    }
);

export const selectCatchmentCustomerProfilesColumns = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectCatchmentCustomerProfiles,
    (isLoading, hasErrors, catchmentCustomerProfiles) => {
        if (isLoading || hasErrors || catchmentCustomerProfiles.length === 0) {
            return [];
        }
        const keys = Object.keys(catchmentCustomerProfiles[0]).filter(key => key !== "storeId");
        const columns: GridColDef[] = keys.map(key => ({
            field: key,
            type: 'number',
            flex: 1,
            headerName: key,
            sortable: true,
            valueGetter: (params) => params.value ?? 0,
            renderCell: (params) => numberFormatter.toDecimalPlaces(params.value ?? 0, 1),
        }));
        return columns;
    }
);

export default reportSlice;
