import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

export class LoggerService {
    private readonly applicationInsights?: ApplicationInsights;

    constructor(applicationInsightsInstrumentationKey: string) {
        if (applicationInsightsInstrumentationKey) {
            this.applicationInsights = new ApplicationInsights({
                config: {
                    extensions: [reactPlugin],
                    instrumentationKey: applicationInsightsInstrumentationKey,
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: browserHistory }
                    },
                    enableAutoRouteTracking: true
                }
            });
            this.applicationInsights.loadAppInsights();
            this.applicationInsights.addTelemetryInitializer((telemetryItem) => {
                telemetryItem.tags = telemetryItem.tags || [];
                telemetryItem.tags["ai.cloud.role"] = "DashSpa";
                if(telemetryItem.baseType === "PageviewData" || telemetryItem.baseType === "PageviewPerformanceData") {
                    if (telemetryItem.baseData != null) {
                        const pageLocation = window.location.hash.replace("#/", "");
                        telemetryItem.baseData.name = pageLocation;
                    }
                }
            });
        }
    }

    private log(message: string, customDimensions: any, severityLevel: SeverityLevel) {
        console.log(message, customDimensions, severityLevel);
        if (this.applicationInsights) {
            this.applicationInsights.trackTrace({
                message,
                severityLevel,
                properties: customDimensions
            });
        }
    }

    debug(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Verbose);
    }

    information(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Information);
    }

    warning(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Warning);
    }

    error(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Error);
    }

    critical(message: string, customDimensions: any) {
        this.log(message, customDimensions, SeverityLevel.Critical);
    }
}
