import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import dateUtils from "utils/dateUtils";

import { loadRetailCentreClassification } from "./retailCentreClassification";
import { loadRagMetrics } from "./ragMetrics";
import { KPMGSpendCategory } from "./kpmgSpendCategory";
import numberFormatter from "utils/numberFormatter";

export enum GeoIndicator {
    GB = "GB",
    NI = "NI"
}

export class Store {
    public readonly id: string;
    public readonly name: string;
    public readonly region: string;
    public readonly geoIndicator: GeoIndicator;
    public readonly outputAreaCode: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly openingDate: Date;
    public readonly sizeInSquareFeet: number;
    public readonly numberOfEmployees: number;
    public readonly retailCentreID: number;
    public readonly kpmgStoreCategory: string;
    public readonly storeCategoryID: number;
    public readonly segment: string;
    public readonly format: string;
    public readonly group: string;
    public readonly clientRegion: string;
    public readonly revenue: number;
    public readonly grossProfitMargin: number;
    public readonly similarStores: SimilarStore[];
    public retailCentreClassificationName: string;
    public kpmgSpendCategories: KPMGSpendCategory[];
    public changeInNumberOfStores: number;
    public catchmentSize: number;
    public numberOfCompetitors: number;
    public footfallLevel: number;
    public areaHealthScore: number;
    public catchmentScore: number;
    public competitionScore: number;
    public footfallScore: number;
    public profitScore: number;
    public revenueScore: number;
    public revenuePerSquareFoot: number;

    constructor(
        id: string,
        name: string,
        region: string,
        geoIndicator: GeoIndicator,
        outputAreaCode: string,
        latitude: number,
        longitude: number,
        openingDate: Date,
        sizeInSquareFeet: number,
        numberOfEmployees: number,
        retailCentreID: number,
        kpmgStoreCategory: string,
        storeCategoryID: number,
        segment: string,
        format: string,
        group: string,
        clientRegion: string,
        revenue: number,
        grossProfitMargin: number,
        similarStores: SimilarStore[],
        retailCentreClassificationName: string,
        kpmgSpendCategories: KPMGSpendCategory[],
        changeInNumberOfStores: number,
        catchmentSize: number,
        numberOfCompetitors: number,
        footfallLevel: number,
        areaHealthScore: number,
        catchmentScore: number,
        competitionScore: number,
        footfallScore: number,
        profitScore: number,
        revenueScore: number,
        revenuePerSquareFoot: number,
    ) {
        this.id = id;
        this.name = name;
        this.region = region;
        this.geoIndicator = geoIndicator;
        this.outputAreaCode = outputAreaCode;
        this.latitude = latitude;
        this.longitude = longitude;
        this.openingDate = openingDate;
        this.sizeInSquareFeet = sizeInSquareFeet;
        this.numberOfEmployees = numberOfEmployees;
        this.retailCentreID = retailCentreID;
        this.kpmgStoreCategory = kpmgStoreCategory;
        this.storeCategoryID = storeCategoryID;
        this.segment = segment;
        this.format = format;
        this.group = group;
        this.clientRegion = clientRegion;
        this.revenue = revenue;
        this.grossProfitMargin = grossProfitMargin;
        this.similarStores = similarStores;
        this.retailCentreClassificationName = retailCentreClassificationName;
        this.kpmgSpendCategories = kpmgSpendCategories;
        this.changeInNumberOfStores = changeInNumberOfStores;
        this.catchmentSize = catchmentSize;
        this.numberOfCompetitors = numberOfCompetitors;
        this.footfallLevel = footfallLevel;
        this.areaHealthScore = areaHealthScore;
        this.catchmentScore = catchmentScore;
        this.competitionScore = competitionScore;
        this.footfallScore = footfallScore;
        this.profitScore = profitScore;
        this.revenueScore = revenueScore;
        this.revenuePerSquareFoot = revenuePerSquareFoot;
    }

    getTotalScore(): number {
        return this.areaHealthScore + this.catchmentScore + this.competitionScore + this.footfallScore + this.profitScore + this.revenueScore;
    }
}

export class StoreWithSimilarityScore extends Store {
    public readonly similarityScore: number | null;

    constructor(
        store: Store,
        similarityScore: number | null
    ) {
        super(
            store.id,
            store.name,
            store.region,
            store.geoIndicator,
            store.outputAreaCode,
            store.latitude,
            store.longitude,
            store.openingDate,
            store.sizeInSquareFeet,
            store.numberOfEmployees,
            store.retailCentreID,
            store.kpmgStoreCategory,
            store.storeCategoryID,
            store.segment,
            store.format,
            store.group,
            store.clientRegion,
            store.revenue,
            store.grossProfitMargin,
            store.similarStores,
            store.retailCentreClassificationName,
            store.kpmgSpendCategories,
            store.changeInNumberOfStores,
            store.catchmentSize,
            store.numberOfCompetitors,
            store.footfallLevel,
            store.areaHealthScore,
            store.catchmentScore,
            store.competitionScore,
            store.footfallScore,
            store.profitScore,
            store.revenueScore,
            store.revenuePerSquareFoot,
        );
        this.similarityScore = similarityScore;
    }

    displaySimilarityScore(): string {
        return this.similarityScore ? numberFormatter.toPercentage(this.similarityScore, true) : "< 70%";
    }
}

class SimilarStore {
    public readonly id: string;
    public readonly similarityScore: number;
    public readonly includedInDashComparator: boolean;

    constructor (
        rawSimilarStore: RawSimilarStore,
    ) {
        this.id = String(rawSimilarStore.SimilarStoreNaturalID);
        this.similarityScore = rawSimilarStore.SimilarityScore;
        this.includedInDashComparator = Boolean(Number(rawSimilarStore.Flag));
    }
}

interface RawSimilarStore {
    SimilarStoreNaturalID: number,
    SimilarityScore: number,
    Flag: number
}

const calculateScore = (sortedStores: Store[], currentStore: Store, chapterMetric: keyof Store): number => {
    const index = sortedStores.findIndex(store => store[chapterMetric] === currentStore[chapterMetric]);
    return 5 * (sortedStores.length - (index + 1)) / (sortedStores.length - 1);
};

export const loadStores = (kpmgSpendCategories: KPMGSpendCategory[], clientId: string): AppThunk<Promise<Store[]>> => async (dispatch) => {
    try {
        const storesQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.StoreName",
                "D_Store.k_Region",
                "D_Store.GeoInd",
                "D_Store.OutputAreaID",
                "D_Store.Lat",
                "D_Store.Long",
                "D_Store.OpeningDate",
                "D_Store.Sqft",
                "D_Store.EmployeeCount",
                "D_Store.RetailCentreID",
                "D_Store.KPMGStoreCategory",
                "D_Store.StoreCategory_ID",
                "D_Store.Segment",
                "D_Store.Format",
                "D_Store.Group",
                "D_Store.ClientRegion",
                "Store_RAGMetrics.Sales",
                "Store_RAGMetrics.Profit",
                "StoreCluster.SimilarStores"
            ],
            filters: [{
                member: "D_Store.OutputAreaID",
                operator: "notEquals",
                values: ["Unknown", "NULL", "Null", "null"]
            }, {
                member: "D_Store.OutputAreaID",
                operator: "set"
            }, {
                member: "D_Store.ClosingDate",
                operator: "notSet"
            }, {
                member: "D_Store.OnlineFlag",
                operator: "notEquals",
                values: ["1"]
            }]
        };
        const resultSetStores = await dispatch(cubeLoad(storesQuery)) as unknown as ResultSet;
        const rawStores = resultSetStores.rawData();

        const stores = rawStores.map(rawStore => new Store(
            rawStore["D_Store.StoreNaturalID"],
            rawStore["D_Store.StoreName"],
            rawStore["D_Store.k_Region"],
            rawStore["D_Store.GeoInd"] === 'GB' ? GeoIndicator.GB : GeoIndicator.NI,
            rawStore["D_Store.OutputAreaID"],
            rawStore["D_Store.Lat"],
            rawStore["D_Store.Long"],
            dateUtils.dateUTC(rawStore["D_Store.OpeningDate"]),
            rawStore["D_Store.Sqft"],
            rawStore["D_Store.EmployeeCount"],
            rawStore["D_Store.RetailCentreID"],
            rawStore["D_Store.KPMGStoreCategory"],
            Number(rawStore["D_Store.StoreCategory_ID"]),
            rawStore["D_Store.Segment"] ?? "Blank",
            rawStore["D_Store.Format"] ?? "Blank",
            rawStore["D_Store.Group"] ?? "Blank",
            rawStore["D_Store.ClientRegion"] ?? "Blank",
            Number(rawStore["Store_RAGMetrics.Sales"]),
            Number(rawStore["Store_RAGMetrics.Profit"]),
            JSON.parse(rawStore["StoreCluster.SimilarStores"] ?? '[]')
                .map((rawSimilarStore: RawSimilarStore) => new SimilarStore(rawSimilarStore)),
            "",
            [],
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ));

        const retailCentreClassificationsPromise = dispatch(loadRetailCentreClassification(stores));
        const ragMetricsPromise = dispatch(loadRagMetrics(stores, clientId));

        const results = await Promise.all([retailCentreClassificationsPromise, ragMetricsPromise]);
        const retailCentreClassifications = results[0];
        const ragMetrics = results[1];

        const query = {
            measures: ["F_Sales.SumLineValue"],
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_ProductCategory.SpendCategory_ID",
                "D_ProductCategory.ProductCategory3"],
            filters: [{
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "notEquals",
                values: ["-1"]
            }, {
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "set"
            }, {
                member: "D_Product.CurrentRecord",
                operator: "equals",
                values: ["Y"]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawStoreProductCategories = resultSet.rawData();

        const allClientKPMGSpendCategories = [...kpmgSpendCategories];

        for (const i in stores) {
            const retailCentreClassification = retailCentreClassifications.find(item => item.retailCentreId.toString() === stores[i].retailCentreID.toString());
            const ragMetric = ragMetrics.find(item => item.retailCentreId.toString() === stores[i].retailCentreID.toString());

            const relevantSpendCategories = rawStoreProductCategories
                .filter(item => item["D_Store.StoreNaturalID"] === stores[i].id)
                .map(item => new KPMGSpendCategory(
                    Number(item["D_ProductCategory.SpendCategory_ID"]),
                    String(item["D_ProductCategory.ProductCategory3"])
                ));

            stores[i].kpmgSpendCategories = relevantSpendCategories.length === 0 ? allClientKPMGSpendCategories : relevantSpendCategories;

            if (retailCentreClassification) {
                stores[i].retailCentreClassificationName = retailCentreClassification.retailCentreClassificationName;
            }
            else {
                stores[i].retailCentreClassificationName = "Single Pitch Site";
            }

            if (ragMetric) {
                stores[i].changeInNumberOfStores = ragMetric.areaHealth;
                stores[i].catchmentSize = ragMetric.catchment;
                stores[i].numberOfCompetitors = ragMetric.competition;
                stores[i].footfallLevel = ragMetric.footfall;
            }
        }

        const storesSortedByAreaHealth = [...stores].sort((a, b) => b.changeInNumberOfStores - a.changeInNumberOfStores);
        const storesSortedByCatchment = [...stores].sort((a, b) => b.catchmentSize - a.catchmentSize);
        const storesSortedByCompetition = [...stores].sort((a, b) => b.numberOfCompetitors - a.numberOfCompetitors);
        const storesSortedByFootfall = [...stores].sort((a, b) => b.footfallLevel - a.footfallLevel);
        const storesSortedByProfit = [...stores].sort((a, b) => b.grossProfitMargin - a.grossProfitMargin);
        const storesSortedByRevenue = [...stores].sort((a, b) => b.revenue - a.revenue);

        stores.forEach(store => {
            store.areaHealthScore = calculateScore(storesSortedByAreaHealth, store, 'changeInNumberOfStores');
            store.catchmentScore = calculateScore(storesSortedByCatchment, store, 'catchmentSize');
            store.competitionScore = calculateScore(storesSortedByCompetition, store, 'numberOfCompetitors');
            store.footfallScore = calculateScore(storesSortedByFootfall, store, 'footfallLevel');
            store.profitScore = calculateScore(storesSortedByProfit, store, 'grossProfitMargin');
            store.revenueScore = calculateScore(storesSortedByRevenue, store, 'revenue');
            store.revenuePerSquareFoot = (store.revenue / store.sizeInSquareFeet);
        });

        return stores;
    } catch (error) {
        dispatch(logError("Error loading Stores.", error));
        throw error;
    }
};
