import { AppThunk } from "appThunk";
import { Comparator } from "modules/customer/tools/location/comparator";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { CatchmentAccountIds } from "modules/customer/tools/location/locationSlice";
import { ResultSet } from "@cubejs-client/core";

export enum DemographicIndicator {
    HouseholdIncome = "householdIncome",
    HouseholdOccupancy = "householdOccupancy",
    HouseholdTenure = "householdTenure",
    AgeStructureGB = "ageStructureGB",
    AgeStructureNI = "ageStructureNI",
    ChildrenAgeStructure = "childrenAgeStructure",
    CountryOfBirth = "countryOfBirth",
    Ethnicity = "ethnicity",
    FirstLanguage = "firstLanguage",
    DwellingType = "dwellingType",
    HouseholdNumberOfCars = "householdNumberOfCars",
    PopulationDensity = "populationDensity"
}

export enum DemographicIndicatorMeasure {
    PercentageOfPopulation,
    PopulationInThousands
}

class DwellingType {
    public readonly detached: number;
    public readonly semiDetached: number;
    public readonly terrace: number;
    public readonly flat: number;
    public readonly otherAccommodation: number;

    constructor(
        detached: number,
        semiDetached: number,
        terrace: number,
        flat: number,
        otherAccommodation: number
    ) {
        this.detached = detached;
        this.semiDetached = semiDetached;
        this.terrace = terrace;
        this.flat = flat;
        this.otherAccommodation = otherAccommodation;
    }
}

class HouseholdTenure {
    public readonly ownedOutright: number;
    public readonly ownedWithLoan: number;
    public readonly socialRent: number;
    public readonly privateRent: number;
    public readonly sharedOwnership: number;
    public readonly livingRentFree: number;

    constructor(
        ownedOutright: number,
        ownedWithLoan: number,
        socialRent: number,
        privateRent: number,
        sharedOwnership: number,
        livingRentFree: number
    ) {
        this.ownedOutright = ownedOutright;
        this.ownedWithLoan = ownedWithLoan;
        this.socialRent = socialRent;
        this.privateRent = privateRent;
        this.sharedOwnership = sharedOwnership;
        this.livingRentFree = livingRentFree;
    }
}

class ChildrenAgeStructure {
    public readonly childrenAgeLessThan1: number;
    public readonly childrenAge1to2: number;
    public readonly childrenAge3to5: number;
    public readonly childrenAge6to10: number;
    public readonly childrenAge11to16: number;

    constructor(
        childrenAgeLessThan1: number,
        childrenAge1to2: number,
        childrenAge3to5: number,
        childrenAge6to10: number,
        childrenAge11to16: number
    ) {
        this.childrenAgeLessThan1 = childrenAgeLessThan1;
        this.childrenAge1to2 = childrenAge1to2;
        this.childrenAge3to5 = childrenAge3to5;
        this.childrenAge6to10 = childrenAge6to10;
        this.childrenAge11to16 = childrenAge11to16;
    }
}

class HouseholdOccupancy {
    public readonly occupancyRating2Plus: number;
    public readonly occupancyRating1: number;
    public readonly occupancyRating0: number;
    public readonly occupancyRatingMinus1: number;
    public readonly occupancyRatingMinus2orLess: number;

    constructor(
        occupancyRating2Plus: number,
        occupancyRating1: number,
        occupancyRating0: number,
        occupancyRatingMinus1: number,
        occupancyRatingMinus2orLess: number
    ) {
        this.occupancyRating2Plus = occupancyRating2Plus;
        this.occupancyRating1 = occupancyRating1;
        this.occupancyRating0 = occupancyRating0;
        this.occupancyRatingMinus1 = occupancyRatingMinus1;
        this.occupancyRatingMinus2orLess = occupancyRatingMinus2orLess;
    }
}

class AgeStructureGB {
    public readonly age0to16: number;
    public readonly age17to25: number;
    public readonly age26to35: number;
    public readonly age36to45: number;
    public readonly age46to55: number;
    public readonly age56to65: number;
    public readonly age66Plus: number;

    constructor(
        age0to16: number,
        age17to25: number,
        age26to35: number,
        age36to45: number,
        age46to55: number,
        age56to65: number,
        age66Plus: number
    ) {
        this.age0to16 = age0to16;
        this.age17to25 = age17to25;
        this.age26to35 = age26to35;
        this.age36to45 = age36to45;
        this.age46to55 = age46to55;
        this.age56to65 = age56to65;
        this.age66Plus = age66Plus;
    }
}

export class AgeStructureNI {
    public readonly age0to15: number;
    public readonly age16to24: number;
    public readonly age25to44: number;
    public readonly age45to64: number;
    public readonly age65Plus: number;

    constructor(
        age0to15: number,
        age16to24: number,
        age25to44: number,
        age45to64: number,
        age65Plus: number
    ) {
        this.age0to15 = age0to15;
        this.age16to24 = age16to24;
        this.age25to44 = age25to44;
        this.age45to64 = age45to64;
        this.age65Plus = age65Plus;
    }
}

class CountryOfBirth {
    public readonly bornInUK: number;
    public readonly emigratedToUK: number;

    constructor(bornInUK: number, emigratedToUK: number) {
        this.bornInUK = bornInUK;
        this.emigratedToUK = emigratedToUK;
    }
}

class Ethnicity {
    public readonly arab: number;
    public readonly asianOther: number;
    public readonly bangladeshi: number;
    public readonly blackAfrican: number;
    public readonly blackCaribbean: number;
    public readonly blackOther: number;
    public readonly chinese: number;
    public readonly indian: number;
    public readonly mixed: number;
    public readonly other: number;
    public readonly pakistani: number;
    public readonly white: number;

    constructor(
        arab: number,
        asianOther: number,
        bangladeshi: number,
        blackAfrican: number,
        blackCaribbean: number,
        blackOther: number,
        chinese: number,
        indian: number,
        mixed: number,
        other: number,
        pakistani: number,
        white: number
    ) {
        this.arab = arab;
        this.asianOther = asianOther;
        this.bangladeshi = bangladeshi;
        this.blackAfrican = blackAfrican;
        this.blackCaribbean = blackCaribbean;
        this.blackOther = blackOther;
        this.chinese = chinese;
        this.indian = indian;
        this.mixed = mixed;
        this.other = other;
        this.pakistani = pakistani;
        this.white = white;
    }
}

class FirstLanguage {
    public readonly englishOrWelsh: number;
    public readonly other: number;

    constructor(englishOrWelsh: number, other: number) {
        this.englishOrWelsh = englishOrWelsh;
        this.other = other;
    }
}

class HouseholdNumberOfCars {
    public readonly car3Plus: number;
    public readonly car2: number;
    public readonly car1: number;
    public readonly car0: number;

    constructor(
        car3Plus: number,
        car2: number,
        car1: number,
        car0: number
    ) {
        this.car3Plus = car3Plus;
        this.car2 = car2;
        this.car1 = car1;
        this.car0 = car0;
    }
}

class PopulationDensity {
    public readonly popDensityLess2000: number;
    public readonly popDensity2000to3999: number;
    public readonly popDensity4000to5999: number;
    public readonly popDensity6000to10000: number;
    public readonly popDensity10000Plus: number;

    constructor(
        popDensityLess2000: number,
        popDensity2000to3999: number,
        popDensity4000to5999: number,
        popDensity6000to10000: number,
        popDensity10000Plus: number
    ) {
        this.popDensityLess2000 = popDensityLess2000;
        this.popDensity2000to3999 = popDensity2000to3999;
        this.popDensity4000to5999 = popDensity4000to5999;
        this.popDensity6000to10000 = popDensity6000to10000;
        this.popDensity10000Plus = popDensity10000Plus;
    }
}

class HouseholdIncome {
    public readonly countIncome100kPlus: number;
    public readonly countIncome90to100k: number;
    public readonly countIncome80to90k: number;
    public readonly countIncome70to80k: number;
    public readonly countIncome60to70k: number;
    public readonly countIncome50to60k: number;
    public readonly countIncome40to50k: number;
    public readonly countIncome30to40k: number;
    public readonly countIncome20to30k: number;
    public readonly countIncomeSub20k: number;

    constructor(
        countIncome100kPlus: number,
        countIncome90to100k: number,
        countIncome80to90k: number,
        countIncome70to80k: number,
        countIncome60to70k: number,
        countIncome50to60k: number,
        countIncome40to50k: number,
        countIncome30to40k: number,
        countIncome20to30k: number,
        countIncomeSub20k: number
    ) {
        this.countIncome100kPlus = countIncome100kPlus;
        this.countIncome90to100k = countIncome90to100k;
        this.countIncome80to90k = countIncome80to90k;
        this.countIncome70to80k = countIncome70to80k;
        this.countIncome60to70k = countIncome60to70k;
        this.countIncome50to60k = countIncome50to60k;
        this.countIncome40to50k = countIncome40to50k;
        this.countIncome30to40k = countIncome30to40k;
        this.countIncome20to30k = countIncome20to30k;
        this.countIncomeSub20k = countIncomeSub20k;
    }
}

export class CatchmentDemographics {
    public readonly retailCentreId: number;
    public readonly dwellingType: DwellingType;
    public readonly householdTenure: HouseholdTenure;
    public readonly childrenAgeStructure: ChildrenAgeStructure;
    public readonly householdOccupancy: HouseholdOccupancy;
    public readonly ageStructureGB: AgeStructureGB;
    public readonly ageStructureNI: AgeStructureNI;
    public readonly countryOfBirth: CountryOfBirth;
    public readonly ethnicity: Ethnicity;
    public readonly firstLanguage: FirstLanguage;
    public readonly householdNumberOfCars: HouseholdNumberOfCars;
    public readonly populationDensity: PopulationDensity;
    public readonly householdIncome: HouseholdIncome;

    constructor(
        retailCentreId: number,
        dwellingType: DwellingType,
        householdTenure: HouseholdTenure,
        childrenAgeStructure: ChildrenAgeStructure,
        householdOccupancy: HouseholdOccupancy,
        ageStructureGB: AgeStructureGB,
        ageStructureNI: AgeStructureNI,
        countryOfBirth: CountryOfBirth,
        ethnicity: Ethnicity,
        firstLanguage: FirstLanguage,
        householdNumberOfCars: HouseholdNumberOfCars,
        populationDensity: PopulationDensity,
        householdIncome: HouseholdIncome
    ) {
        this.retailCentreId = retailCentreId;
        this.householdIncome = householdIncome;
        this.householdOccupancy = householdOccupancy;
        this.householdTenure = householdTenure;
        this.ageStructureGB = ageStructureGB;
        this.ageStructureNI = ageStructureNI;
        this.childrenAgeStructure = childrenAgeStructure;
        this.countryOfBirth = countryOfBirth;
        this.ethnicity = ethnicity;
        this.firstLanguage = firstLanguage;
        this.dwellingType = dwellingType;
        this.householdNumberOfCars = householdNumberOfCars;
        this.populationDensity = populationDensity;
    }
}

export const loadCatchmentDemographics = (catchmentAccountIds: CatchmentAccountIds, selectedRetailCentreId?: number, comparator?: Comparator, targetStoreCategoryId?: number): AppThunk<Promise<CatchmentDemographics[]>> => async (dispatch) => {
    if (!selectedRetailCentreId || !comparator || !targetStoreCategoryId) {
        return [];
    }

    try {
        const query = {
            dimensions: [
                "CatchmentDemographics.RetailCentreID",
                "CatchmentDemographics.Detached",
                "CatchmentDemographics.SemiDetached",
                "CatchmentDemographics.Terrace",
                "CatchmentDemographics.Flat",
                "CatchmentDemographics.OtherAccommodation",
                "CatchmentDemographics.OwnedOutright",
                "CatchmentDemographics.OwnedWithLoan",
                "CatchmentDemographics.SocialRent",
                "CatchmentDemographics.PrivateRent",
                "CatchmentDemographics.SharedOwnership",
                "CatchmentDemographics.LivingRentFree",
                "CatchmentDemographics.ChildrenAgeLessThan1",
                "CatchmentDemographics.ChildrenAge1to2",
                "CatchmentDemographics.ChildrenAge3to5",
                "CatchmentDemographics.ChildrenAge6to10",
                "CatchmentDemographics.ChildrenAge11to16",
                "CatchmentDemographics.OccupancyRating_2Plus",
                "CatchmentDemographics.OccupancyRating_1",
                "CatchmentDemographics.OccupancyRating_0",
                "CatchmentDemographics.OccupancyRating_Minus1",
                "CatchmentDemographics.OccupancyRating_Minus2orLess",
                "CatchmentDemographics.Age0to16",
                "CatchmentDemographics.Age17to25",
                "CatchmentDemographics.Age26to35",
                "CatchmentDemographics.Age36to45",
                "CatchmentDemographics.Age46to55",
                "CatchmentDemographics.Age56to65",
                "CatchmentDemographics.Age0to15",
                "CatchmentDemographics.Age16to24",
                "CatchmentDemographics.Age25to44",
                "CatchmentDemographics.Age45to64",
                "CatchmentDemographics.Age65Plus",
                "CatchmentDemographics.Age66Plus",
                "CatchmentDemographics.BornInUk",
                "CatchmentDemographics.EmigratedToUk",
                "CatchmentDemographics.EthArab",
                "CatchmentDemographics.EthAsianOther",
                "CatchmentDemographics.EthBangladeshi",
                "CatchmentDemographics.EthBlackAfrican",
                "CatchmentDemographics.EthBlackCaribbean",
                "CatchmentDemographics.EthBlackOther",
                "CatchmentDemographics.EthChinese",
                "CatchmentDemographics.EthIndian",
                "CatchmentDemographics.EthMixed",
                "CatchmentDemographics.EthOther",
                "CatchmentDemographics.EthPakistani",
                "CatchmentDemographics.EthWhite",
                "CatchmentDemographics.LangEnglishOrWelsh",
                "CatchmentDemographics.LangOther",
                "CatchmentDemographics.Car3Plus",
                "CatchmentDemographics.Car2",
                "CatchmentDemographics.Car1",
                "CatchmentDemographics.Car0",
                "CatchmentDemographics.PopDensityLess2000",
                "CatchmentDemographics.PopDensity2000to3999",
                "CatchmentDemographics.PopDensity4000to5999",
                "CatchmentDemographics.PopDensity6000to10000",
                "CatchmentDemographics.PopDensity10000Plus",
                "CatchmentDemographics.CountIncome_100kPlus",
                "CatchmentDemographics.CountIncome_90to100k",
                "CatchmentDemographics.CountIncome_80to90k",
                "CatchmentDemographics.CountIncome_70to80k",
                "CatchmentDemographics.CountIncome_60to70k",
                "CatchmentDemographics.CountIncome_50to60k",
                "CatchmentDemographics.CountIncome_40to50k",
                "CatchmentDemographics.CountIncome_30to40k",
                "CatchmentDemographics.CountIncome_20to30k",
                "CatchmentDemographics.CountIncome_Sub20k"
            ],
            filters: [{
                member: "CatchmentDemographics.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                or: [{
                    and: [{ //Selected RC
                        member: "CatchmentDemographics.RetailCentreID",
                        operator: "equals",
                        values: [String(selectedRetailCentreId)]
                    }, {
                        member: "CatchmentDemographics.IsScenario",
                        operator: "equals",
                        values: ["1"]
                    }, {
                        member: "CatchmentDemographics.Client_ID",
                        operator: "equals",
                        values: [catchmentAccountIds.scenario]
                    }]
                }, {
                    and: [{ //Comparator RC
                        member: "CatchmentDemographics.RetailCentreID",
                        operator: "equals",
                        values: [String(comparator.retailCentreId)]
                    }, {
                        member: "CatchmentDemographics.IsScenario",
                        operator: "equals",
                        values: [String(Number(comparator.scenarioCatchment))]
                    }, {
                        member: "CatchmentDemographics.Client_ID",
                        operator: "equals",
                        values: [catchmentAccountIds.baseline]
                    }]
                }]
            }]
        };

        const results = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawResults = results.rawData();
        return rawResults.map(rawRow => new CatchmentDemographics(
            rawRow["CatchmentDemographics.RetailCentreID"],
            new DwellingType(
                rawRow["CatchmentDemographics.Detached"],
                rawRow["CatchmentDemographics.SemiDetached"],
                rawRow["CatchmentDemographics.Terrace"],
                rawRow["CatchmentDemographics.Flat"],
                rawRow["CatchmentDemographics.OtherAccommodation"]
            ),
            new HouseholdTenure(
                rawRow["CatchmentDemographics.OwnedOutright"],
                rawRow["CatchmentDemographics.OwnedWithLoan"],
                rawRow["CatchmentDemographics.SocialRent"],
                rawRow["CatchmentDemographics.PrivateRent"],
                rawRow["CatchmentDemographics.SharedOwnership"],
                rawRow["CatchmentDemographics.LivingRentFree"]
            ),
            new ChildrenAgeStructure(
                rawRow["CatchmentDemographics.ChildrenAgeLessThan1"],
                rawRow["CatchmentDemographics.ChildrenAge1to2"],
                rawRow["CatchmentDemographics.ChildrenAge3to5"],
                rawRow["CatchmentDemographics.ChildrenAge6to10"],
                rawRow["CatchmentDemographics.ChildrenAge11to16"]
            ),
            new HouseholdOccupancy(
                rawRow["CatchmentDemographics.OccupancyRating_2Plus"],
                rawRow["CatchmentDemographics.OccupancyRating_1"],
                rawRow["CatchmentDemographics.OccupancyRating_0"],
                rawRow["CatchmentDemographics.OccupancyRating_Minus1"],
                rawRow["CatchmentDemographics.OccupancyRating_Minus2orLess"]
            ),
            new AgeStructureGB(
                rawRow["CatchmentDemographics.Age0to16"],
                rawRow["CatchmentDemographics.Age17to25"],
                rawRow["CatchmentDemographics.Age26to35"],
                rawRow["CatchmentDemographics.Age36to45"],
                rawRow["CatchmentDemographics.Age46to55"],
                rawRow["CatchmentDemographics.Age56to65"],
                rawRow["CatchmentDemographics.Age66Plus"]
            ),
            new AgeStructureNI(
                rawRow["CatchmentDemographics.Age0to15"],
                rawRow["CatchmentDemographics.Age16to24"],
                rawRow["CatchmentDemographics.Age25to44"],
                rawRow["CatchmentDemographics.Age45to64"],
                rawRow["CatchmentDemographics.Age65Plus"]
            ),
            new CountryOfBirth(
                rawRow["CatchmentDemographics.BornInUk"],
                rawRow["CatchmentDemographics.EmigratedToUk"]
            ),
            new Ethnicity(
                rawRow["CatchmentDemographics.EthArab"],
                rawRow["CatchmentDemographics.EthAsianOther"],
                rawRow["CatchmentDemographics.EthBangladeshi"],
                rawRow["CatchmentDemographics.EthBlackAfrican"],
                rawRow["CatchmentDemographics.EthBlackCaribbean"],
                rawRow["CatchmentDemographics.EthBlackOther"],
                rawRow["CatchmentDemographics.EthChinese"],
                rawRow["CatchmentDemographics.EthIndian"],
                rawRow["CatchmentDemographics.EthMixed"],
                rawRow["CatchmentDemographics.EthOther"],
                rawRow["CatchmentDemographics.EthPakistani"],
                rawRow["CatchmentDemographics.EthWhite"]
            ),
            new FirstLanguage(
                rawRow["CatchmentDemographics.LangEnglishOrWelsh"],
                rawRow["CatchmentDemographics.LangOther"]
            ),
            new HouseholdNumberOfCars(
                rawRow["CatchmentDemographics.Car3Plus"],
                rawRow["CatchmentDemographics.Car2"],
                rawRow["CatchmentDemographics.Car1"],
                rawRow["CatchmentDemographics.Car0"]
            ),
            new PopulationDensity(
                rawRow["CatchmentDemographics.PopDensityLess2000"],
                rawRow["CatchmentDemographics.PopDensity2000to3999"],
                rawRow["CatchmentDemographics.PopDensity4000to5999"],
                rawRow["CatchmentDemographics.PopDensity6000to10000"],
                rawRow["CatchmentDemographics.PopDensity10000Plus"]
            ),
            new HouseholdIncome(
                rawRow["CatchmentDemographics.CountIncome_100kPlus"],
                rawRow["CatchmentDemographics.CountIncome_90to100k"],
                rawRow["CatchmentDemographics.CountIncome_80to90k"],
                rawRow["CatchmentDemographics.CountIncome_70to80k"],
                rawRow["CatchmentDemographics.CountIncome_60to70k"],
                rawRow["CatchmentDemographics.CountIncome_50to60k"],
                rawRow["CatchmentDemographics.CountIncome_40to50k"],
                rawRow["CatchmentDemographics.CountIncome_30to40k"],
                rawRow["CatchmentDemographics.CountIncome_20to30k"],
                rawRow["CatchmentDemographics.CountIncome_Sub20k"]
            )
        ));
    } catch (error) {
        dispatch(logError("Error loading CatchmentDemographics.", error));
        throw error;
    }
};
