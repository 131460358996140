import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import CatchmentAlignment from "./catchmentAlignment/CatchmentaAignment";
import CompetitionAlignment from "./competitionAlignment/CompetitionAlignment";
import FootfallAlignment from "./footfallAlignment/FootfallAlignment";

const ProductStoreFit: React.FC = () => {
    return (
        <Chapter
            title="Product store fit"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            dataCy="product-store-fit-chapter"
        >
            <Subchapter
                title="CatchmentAlignment"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="catchment-alignment-subchapter"
            >
                <CatchmentAlignment />
            </Subchapter>
            <Subchapter
                title="FootfallAlignment"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="footfall-alignment-subchapter"
            >
                <FootfallAlignment />
            </Subchapter>
            <Subchapter
                title="CompetitionAlignment"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="competition-alignment-subchapter"
            >
                <CompetitionAlignment />
            </Subchapter>
        </Chapter>
    );
};

export default ProductStoreFit;
