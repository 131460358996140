import React from "react";
import _ from "lodash";

import { Badge, Box, Button, Mark, Menu, MenuItem, Slider, TextField, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { selectShowSimilarityScores } from "modules/customer/insights/portfolioNew/portfolioSlice";
import {
    clearCustomSelectionStoresFilter,
    selectCustomSelectionStoresFilter,
    selectIsCustomSelectionStoresFilterModified,
    selectRegions,
    selectStoreCategories,
    selectPitchTypes,
    selectGroupTypes,
    selectFormatTypes,
    selectSegmentTypes,
    selectFirstAndLatestStoreOpeningDate,
    selectStoreSizes,
    selectNumberOfEmployees,
    selectRevenue,
    selectGrossProfitMargin,
    selectRevenuePerSquareFoot,
    selectCatchmentSize,
    selectNumberOfCompetitors,
    selectChangeInNumberOfStores,
    selectFootfallLevel,
    setCustomSelectionStoresFilter,
    CustomSelectionStoresFilter,
    selectClientRegions
} from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import { selectUserInfo } from "modules/auth/authSlice";

const StyledKeyboardDatePicker = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main,
            color: theme.palette.common.white,
            marginTop: theme.spacing(-2)
        },
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: theme.spacing(0)
        },
        "& .MuiIconButton-root:hover": {
            backgroundColor: "transparent"
        },
        "& .MuiFormHelperText-contained": {
            margin: theme.spacing(0)
        }
    },
}))(KeyboardDatePicker);

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(40),
        maxHeight: "70vh"
    }
}))(Menu);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    }
}))(TextField);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const clientRegions = useAppSelector(selectClientRegions);
    const regions = useAppSelector(selectRegions);
    const storeCategories = useAppSelector(selectStoreCategories);
    const pitchTypes = useAppSelector(selectPitchTypes);
    const groupTypes = useAppSelector(selectGroupTypes);
    const formatTypes = useAppSelector(selectFormatTypes);
    const segmentTypes = useAppSelector(selectSegmentTypes);
    const openingDateRange = useAppSelector(selectFirstAndLatestStoreOpeningDate);
    const storeSizes = useAppSelector(selectStoreSizes);
    const numberOfEmployees = useAppSelector(selectNumberOfEmployees);
    const revenue = useAppSelector(selectRevenue);
    const grossProfitMargin = useAppSelector(selectGrossProfitMargin);
    const revenuePerSquareFoot = useAppSelector(selectRevenuePerSquareFoot);
    const catchmentSize = useAppSelector(selectCatchmentSize);
    const numberOfCompetitors = useAppSelector(selectNumberOfCompetitors);
    const changeInNumberOfStores = useAppSelector(selectChangeInNumberOfStores);
    const footfallLevel = useAppSelector(selectFootfallLevel);
    const customSelectionStoresFilter = useAppSelector(selectCustomSelectionStoresFilter);
    const isCustomSelectionStoresFilterModified = useAppSelector(selectIsCustomSelectionStoresFilterModified);
    const showSimilarityScores = useAppSelector(selectShowSimilarityScores);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [currentCustomSelectionStoresFilter, setCurrentCustomSelectionStoresFilter] = React.useState<CustomSelectionStoresFilter>(customSelectionStoresFilter);

    // Similarity slider values
    const similaritySliderMin = Math.max(0, currentCustomSelectionStoresFilter.similarity[0]);
    const similaritySliderMax = Math.min(100, currentCustomSelectionStoresFilter.similarity[1]);
    const similarityStep = (100 - 0) / 5;
    const similarityMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        if (similarityStep === 0 || similarityStep === Infinity || similarityStep === -Infinity) {
            return marks;
        }
        for (let i = 0; i <= 5; i++) {
            const value = i * 20;
            const label = numberFormatter.toPercentage(value, true, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [similarityStep]);

    // Store size slider values
    const storeSizePercentileThresholds = storeSizes.percentileThresholds;
    const storeSizeSliderMinValue = storeSizePercentileThresholds.indexOf(Math.max(storeSizes.minPercentileThreshold, currentCustomSelectionStoresFilter.storeSize[0]));
    const storeSizeSliderMaxValue = storeSizePercentileThresholds.indexOf(Math.min(storeSizes.maxPercentileThreshold, currentCustomSelectionStoresFilter.storeSize[1]));
    const storeSizeSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < storeSizePercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toDecimalPlaces(storeSizePercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [storeSizePercentileThresholds]);

    // Number of employees slider values
    const numberOfEmployeesPercentileThresholds = numberOfEmployees.percentileThresholds;
    const numberOfEmployeesSliderMinValue = numberOfEmployeesPercentileThresholds.indexOf(Math.max(numberOfEmployees.minPercentileThreshold, currentCustomSelectionStoresFilter.numberOfEmployees[0]));
    const numberOfemployeesSliderMaxValue = numberOfEmployeesPercentileThresholds.indexOf(Math.min(numberOfEmployees.maxPercentileThreshold, currentCustomSelectionStoresFilter.numberOfEmployees[1]));
    const numberOfEmployeesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < numberOfEmployeesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toDecimalPlaces(numberOfEmployeesPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [numberOfEmployeesPercentileThresholds]);

    // Revenue slider values
    const revenuePercentileThresholds = revenue.percentileThresholds;
    const revenueSliderMinValue = revenuePercentileThresholds.indexOf(Math.max(revenue.minPercentileThreshold, currentCustomSelectionStoresFilter.revenue[0]));
    const revenueSliderMaxValue = revenuePercentileThresholds.indexOf(Math.min(revenue.maxPercentileThreshold, currentCustomSelectionStoresFilter.revenue[1]));
    const revenueSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < revenuePercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(revenuePercentileThresholds[i], 1);
            marks.push({ value, label });
        }
        return marks;
    }, [revenuePercentileThresholds]);

    // Gross profit margin slider values
    const grossProfitMarginPercentileThresholds = grossProfitMargin.percentileThresholds;
    const grossProfitMarginSliderMinValue = grossProfitMarginPercentileThresholds.indexOf(Math.max(grossProfitMargin.minPercentileThreshold, currentCustomSelectionStoresFilter.grossProfitMargin[0]));
    const grossProfitMarginSliderMaxValue = grossProfitMarginPercentileThresholds.indexOf(Math.min(grossProfitMargin.maxPercentileThreshold, currentCustomSelectionStoresFilter.grossProfitMargin[1]));
    const grossProfitMarginSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < grossProfitMarginPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toPercentage(grossProfitMarginPercentileThresholds[i], false, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [grossProfitMarginPercentileThresholds]);

    // Revenue/ft slider values
    const revenuePerSquareFootPercentileThresholds = revenuePerSquareFoot.percentileThresholds;
    const revenuePerSquareFootSliderMinValue = revenuePerSquareFootPercentileThresholds.indexOf(Math.max(revenuePerSquareFoot.minPercentileThreshold, currentCustomSelectionStoresFilter.revenuePerSquareFoot[0]));
    const revenuePerSquareFootSliderMaxValue = revenuePerSquareFootPercentileThresholds.indexOf(Math.min(revenuePerSquareFoot.maxPercentileThreshold, currentCustomSelectionStoresFilter.revenuePerSquareFoot[1]));
    const revenuePerSquareFootSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < revenuePerSquareFootPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(revenuePerSquareFootPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [revenuePerSquareFootPercentileThresholds]);

    // Catchment size slider values
    const catchmentSizePercentileThresholds = catchmentSize.percentileThresholds;
    const catchmentSizeSliderMinValue = catchmentSizePercentileThresholds.indexOf(Math.max(catchmentSize.minPercentileThreshold, currentCustomSelectionStoresFilter.catchmentSize[0]));
    const catchmentSizeSliderMaxValue = catchmentSizePercentileThresholds.indexOf(Math.min(catchmentSize.maxPercentileThreshold, currentCustomSelectionStoresFilter.catchmentSize[1]));
    const catchmentSizeSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < catchmentSizePercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toDecimalPlaces(catchmentSizePercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [catchmentSizePercentileThresholds]);

    // Number of competitors slider values
    const numberOfCompetitorsPercentileThresholds = numberOfCompetitors.percentileThresholds;
    const numberOfCompetitorsSliderMinValue = numberOfCompetitorsPercentileThresholds.indexOf(Math.max(numberOfCompetitors.minPercentileThreshold, currentCustomSelectionStoresFilter.numberOfCompetitors[0]));
    const numberOfCompetitorsSliderMaxValue = numberOfCompetitorsPercentileThresholds.indexOf(Math.min(numberOfCompetitors.maxPercentileThreshold, currentCustomSelectionStoresFilter.numberOfCompetitors[1]));
    const numberOfCompetitorsSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < numberOfCompetitorsPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toDecimalPlaces(numberOfCompetitorsPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [numberOfCompetitorsPercentileThresholds]);

    // Change in number of stores slider values
    const changeInNumberOfStoresPercentileThresholds = changeInNumberOfStores.percentileThresholds;
    const changeInNumberOfStoresSliderMinValue = changeInNumberOfStoresPercentileThresholds.indexOf(Math.max(changeInNumberOfStores.minPercentileThreshold, currentCustomSelectionStoresFilter.changeInNumberOfStores[0]));
    const changeInNumberOfStoresSliderMaxValue = changeInNumberOfStoresPercentileThresholds.indexOf(Math.min(changeInNumberOfStores.maxPercentileThreshold, currentCustomSelectionStoresFilter.changeInNumberOfStores[1]));
    const changeInNumberOfStoresSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < changeInNumberOfStoresPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toPercentage(changeInNumberOfStoresPercentileThresholds[i], false, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [changeInNumberOfStoresPercentileThresholds]);

    // Footfall level slider values
    const footfallLevelPercentileThresholds = footfallLevel.percentileThresholds;
    const footfallLevelSliderMinValue = footfallLevelPercentileThresholds.indexOf(Math.max(footfallLevel.minPercentileThreshold, currentCustomSelectionStoresFilter.footfallLevel[0]));
    const footfallLevelSliderMaxValue = footfallLevelPercentileThresholds.indexOf(Math.min(footfallLevel.maxPercentileThreshold, currentCustomSelectionStoresFilter.footfallLevel[1]));
    const footfallLevelSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < footfallLevelPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toDecimalPlaces(footfallLevelPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [footfallLevelPercentileThresholds]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetAllClick = () => {
        dispatch(clearCustomSelectionStoresFilter());
    };

    const setCustomSelectionStoresFilterDelayed = React.useMemo(() => {
        return _.debounce((customSelectionStoresFilter: CustomSelectionStoresFilter) => dispatch(setCustomSelectionStoresFilter(customSelectionStoresFilter)), 400);
    }, [dispatch]);

    const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const region = event.target.value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, region };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleClientRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const clientRegion = event.target.value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, clientRegion };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleStoreCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const storeCategory = event.target.value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, storeCategory };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handlePitchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pitchType = event.target.value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, pitchType };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleGroupTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const groupType = event.target.value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, groupType };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleFormatTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formatType = event.target.value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, formatType };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleSegmentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const segmentType = event.target.value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, segmentType };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleStoreOpeningDateFromChange = (date: any) => {
        const openingDateFrom = date;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, openingDateFrom };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleStoreOpeningDateToChange = (date: any) => {
        const openingDateTo = date;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, openingDateTo };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleSimilarityChange = (event: React.ChangeEvent<{}>, value: any) => {
        const similarity = value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, similarity };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleStoreSizeChange = (event: React.ChangeEvent<{}>, value: any) => {
        const storeSize = [storeSizePercentileThresholds[value[0]], storeSizePercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, storeSize };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleEmployeesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const numberOfEmployees = [numberOfEmployeesPercentileThresholds[value[0]], numberOfEmployeesPercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, numberOfEmployees };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleRevenueChange = (event: React.ChangeEvent<{}>, value: any) => {
        const revenue = [revenuePercentileThresholds[value[0]], revenuePercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, revenue };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleGrossProfitMarginChange = (event: React.ChangeEvent<{}>, value: any) => {
        const grossProfitMargin = [grossProfitMarginPercentileThresholds[value[0]], grossProfitMarginPercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, grossProfitMargin };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleRevenuePerSquareFootChange = (event: React.ChangeEvent<{}>, value: any) => {
        const revenuePerSquareFoot = [revenuePerSquareFootPercentileThresholds[value[0]], revenuePerSquareFootPercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, revenuePerSquareFoot };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleCatchmentSizeChange = (event: React.ChangeEvent<{}>, value: any) => {
        const catchmentSize = [catchmentSizePercentileThresholds[value[0]], catchmentSizePercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, catchmentSize };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleNumberOfCompetitorsChange = (event: React.ChangeEvent<{}>, value: any) => {
        const numberOfCompetitors = [numberOfCompetitorsPercentileThresholds[value[0]], numberOfCompetitorsPercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, numberOfCompetitors };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleChangeInNumberOfStoresChange = (event: React.ChangeEvent<{}>, value: any) => {
        const changeInNumberOfStores = [changeInNumberOfStoresPercentileThresholds[value[0]], changeInNumberOfStoresPercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, changeInNumberOfStores };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleFootfallLevelChange = (event: React.ChangeEvent<{}>, value: any) => {
        const footfallLevel = [footfallLevelPercentileThresholds[value[0]], footfallLevelPercentileThresholds[value[1]]];
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, footfallLevel };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    React.useEffect(() => {
        setCurrentCustomSelectionStoresFilter(customSelectionStoresFilter);
    }, [customSelectionStoresFilter]);

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isCustomSelectionStoresFilterModified}>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disableElevation
                    startIcon={<FilterListIcon />}
                    onClick={handleClick}
                    data-cy="btn-filter"
                >
                    Filter
                </Button>
            </Badge>
            <StyledMenu
                open={open}
                onClose={handleClose}
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Box paddingBottom={2} paddingRight={2} paddingLeft={2}>
                    <Box display="flex">
                        <Spacer />
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleResetAllClick}
                        >
                            Reset all
                        </Button>
                    </Box>
                    {(clientRegions.length > 1) && <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            {userInfo.companyDisplayName} Region
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentCustomSelectionStoresFilter.clientRegion}
                            onChange={handleClientRegionChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select region
                            </MenuItem>
                            {clientRegions.map((clientRegion, index) =>
                                <MenuItem key={index} value={clientRegion}>
                                    {clientRegion}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>}
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Region
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentCustomSelectionStoresFilter.region}
                            onChange={handleRegionChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select region
                            </MenuItem>
                            {regions.map((region, index) =>
                                <MenuItem key={index} value={region}>
                                    {region}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Store category
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentCustomSelectionStoresFilter.storeCategory}
                            onChange={handleStoreCategoryChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select store category
                            </MenuItem>
                            {storeCategories.map((category, index) =>
                                <MenuItem key={index} value={category}>
                                    {category}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Pitch type
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentCustomSelectionStoresFilter.pitchType}
                            onChange={handlePitchTypeChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select pitch type
                            </MenuItem>
                            {pitchTypes.map((pitchType, index) =>
                                <MenuItem key={index} value={pitchType}>
                                    {pitchType}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    {(groupTypes.length > 1) && <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Group type
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentCustomSelectionStoresFilter.groupType}
                            onChange={handleGroupTypeChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select group type
                            </MenuItem>
                            {groupTypes.map((groupType, index) =>
                                <MenuItem key={index} value={groupType}>
                                    {groupType}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>}
                    {(formatTypes.length > 1) && <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Format type
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentCustomSelectionStoresFilter.formatType}
                            onChange={handleFormatTypeChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select format type
                            </MenuItem>
                            {formatTypes.map((formatType, index) =>
                                <MenuItem key={index} value={formatType}>
                                    {formatType}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>}
                    {(segmentTypes.length > 1) && <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Segment type
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentCustomSelectionStoresFilter.segmentType}
                            onChange={handleSegmentTypeChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select segment type
                            </MenuItem>
                            {segmentTypes.map((segmentType, index) =>
                                <MenuItem key={index} value={segmentType}>
                                    {segmentType}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>}
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Opening date between
                        </Typography>
                        <StyledKeyboardDatePicker
                            variant="inline"
                            inputVariant="outlined"
                            size="small"
                            color="primary"
                            margin="normal"
                            format="dd/MM/yyyy"
                            minDate={openingDateRange.firstOpeningDate}
                            minDateMessage={`Date should not be before ${openingDateRange.firstOpeningDate.toLocaleDateString()}`}
                            maxDate={openingDateRange.lastOpeningDate}
                            maxDateMessage={`Date should not be after ${openingDateRange.lastOpeningDate.toLocaleDateString()}`}
                            value={(!(currentCustomSelectionStoresFilter.openingDateFrom) ? openingDateRange.firstOpeningDate : currentCustomSelectionStoresFilter.openingDateFrom)}
                            onChange={handleStoreOpeningDateFromChange}
                            fullWidth
                            disableToolbar
                            disableFuture
                            autoOk
                            KeyboardButtonProps={{
                                disableRipple: true
                            }}
                        />
                    </Box>
                    <Box paddingBottom={1} paddingLeft={1} paddingRight={1}>
                        <StyledKeyboardDatePicker
                            variant="inline"
                            inputVariant="outlined"
                            size="small"
                            color="primary"
                            margin="normal"
                            format="dd/MM/yyyy"
                            minDate={(!(currentCustomSelectionStoresFilter.openingDateFrom) ? openingDateRange.firstOpeningDate : currentCustomSelectionStoresFilter.openingDateFrom)}
                            minDateMessage={`Date should not be before ${(
                                !(currentCustomSelectionStoresFilter.openingDateFrom)
                                    ? openingDateRange.firstOpeningDate.toLocaleDateString()
                                    : currentCustomSelectionStoresFilter.openingDateFrom.toLocaleDateString()
                            )}`
                            }
                            value={(!(currentCustomSelectionStoresFilter.openingDateTo) ? openingDateRange.lastOpeningDate : currentCustomSelectionStoresFilter.openingDateTo)}
                            onChange={handleStoreOpeningDateToChange}
                            fullWidth
                            disableToolbar
                            disableFuture
                            maxDateMessage="Date should not be after today"
                            autoOk
                            KeyboardButtonProps={{
                                disableRipple: true
                            }}
                        />
                    </Box>
                    {(showSimilarityScores === true) && (<Box padding={1}>
                        <Typography variant="body1" component="div">Similarity</Typography>
                        <Slider
                            value={[similaritySliderMin, similaritySliderMax]}
                            onChange={handleSimilarityChange}
                            step={similarityStep}
                            min={0}
                            max={100}
                            marks={similarityMarks}
                        />
                    </Box>)}
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Square footage</Typography>
                        <Slider
                            value={[storeSizeSliderMinValue, storeSizeSliderMaxValue]}
                            onChange={handleStoreSizeChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={storeSizeSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Employees</Typography>
                        <Slider
                            value={[numberOfEmployeesSliderMinValue, numberOfemployeesSliderMaxValue]}
                            onChange={handleEmployeesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={numberOfEmployeesSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Revenue in the last year</Typography>
                        <Slider
                            value={[revenueSliderMinValue, revenueSliderMaxValue]}
                            onChange={handleRevenueChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={revenueSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Gross profit (%))</Typography>
                        <Slider
                            value={[grossProfitMarginSliderMinValue, grossProfitMarginSliderMaxValue]}
                            onChange={handleGrossProfitMarginChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={grossProfitMarginSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Revenue per square foot</Typography>
                        <Slider
                            value={[revenuePerSquareFootSliderMinValue, revenuePerSquareFootSliderMaxValue]}
                            onChange={handleRevenuePerSquareFootChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={revenuePerSquareFootSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Catchment size (population)</Typography>
                        <Slider
                            value={[catchmentSizeSliderMinValue, catchmentSizeSliderMaxValue]}
                            onChange={handleCatchmentSizeChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={catchmentSizeSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Number of competitors within 5kms</Typography>
                        <Slider
                            value={[numberOfCompetitorsSliderMinValue, numberOfCompetitorsSliderMaxValue]}
                            onChange={handleNumberOfCompetitorsChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={numberOfCompetitorsSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Change in number of stores</Typography>
                        <Slider
                            value={[changeInNumberOfStoresSliderMinValue, changeInNumberOfStoresSliderMaxValue]}
                            onChange={handleChangeInNumberOfStoresChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={changeInNumberOfStoresSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Footfall level</Typography>
                        <Slider
                            value={[footfallLevelSliderMinValue, footfallLevelSliderMaxValue]}
                            onChange={handleFootfallLevelChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={footfallLevelSliderMarks}
                        />
                    </Box>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;
