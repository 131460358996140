import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";

import RegionsMap, { pointerInfoEmpty } from "components/visuals/maps/RegionsMap";

import TotalSalesGrowthByRegionForecastMapPopup from "./TotalSalesGrowthByRegionForecastMapPopup";

import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { operations, selectors } from "modules/customer/insights/performance/forecast/regionalBreakdown";

const TotalSalesGrowthByRegionForecast = () => {
    const dispatch = useDispatch();
    const mapboxConfiguration = useSelector(selectMapboxConfiguration);
    const totalSalesGrowthByRegionForecast = useSelector(state => selectors.totalSalesGrowthByRegionForecast(state));
    const { loading, error, regionsGrowthForecast } = totalSalesGrowthByRegionForecast;
    const regions = regionsGrowthForecast.map(r => ({
        code: r.regionId,
        colourGroup: r.group
    }))
    .filter(r => r.code != null && r.colourGroup != null);
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const [clickInfo, setClickInfo] = React.useState(pointerInfoEmpty);

    React.useEffect(() => {
        dispatch(operations.getTotalSalesGrowthByRegionForecast());
    }, [dispatch]);

    const handleClick = (pointerInfo) => {
        setClickInfo(pointerInfo);
        dispatch(operations.setSelectedRegion(pointerInfo.regionCode ?? ""));
    };

    return (
        <Box data-cy="total-sales-growth-by-region-forecast">
            <RegionsMap
                loading={loading}
                error={error}
                title={"Forecast year-on-year revenue growth (%) by region"}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxRegionsTilesetId={mapboxConfiguration.regionsTilesetId}
                mapboxRegionsTilesetUrl={mapboxConfiguration.regionsTilesetUrl}
                regions={regions}
                onHover={setHoverInfo}
                onClick={handleClick}
                downloadData={regionsGrowthForecast}
            >
                <TotalSalesGrowthByRegionForecastMapPopup
                    hoverInfo={hoverInfo}
                    clickInfo={clickInfo}
                    regions={regionsGrowthForecast}
                />
            </RegionsMap>
        </Box>
    );
};

export default TotalSalesGrowthByRegionForecast;
