import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectUniquePopulation } from "modules/customer/insights/portfolioNew/competition/competitionSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const UniquePopulation: React.FC = () => {
    const selectedStore = useAppSelector(selectStore);
    const { isLoading, hasErrors, data: uniquePopulation } = useAppSelector(selectUniquePopulation);
    const valueFormatted = numberFormatter.toDecimalPlaces(uniquePopulation, 1);
    const label = `Catchment area population exclusive to your ${selectedStore?.name} store`;

    return (
        <Box width="100%" height="100%" data-cy="unique-population">
            <KPIFact loading={isLoading} error={hasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default UniquePopulation;
