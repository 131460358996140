import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import { CatchmentCustomerProfile } from "modules/customer/tools/location/catchmentCustomerProfile";
import {
    selectCatchmentAccountIds,
    selectCatchmentCustomerProfiles,
    selectCatchmentPopulation,
    selectComparatorsByChapter,
    selectHasErrors as selectLocationHasErrors,
    selectIsLoading as selectLocationIsLoading,
    selectPinnedLocation,
    selectTarget,
    selectTargetStoreCategory
} from "modules/customer/tools/location/locationSlice";
import { ScoreField } from "modules/customer/tools/location/retailCentre";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

import {
    CatchmentDemographics,
    DemographicIndicator,
    DemographicIndicatorMeasure,
    loadCatchmentDemographics
} from "./catchmentDemographics";
import { loadPenPortraits, PenPortrait } from "./penPortrait";

interface LoadDemographicsResponse {
    penPortraits: PenPortrait[],
    catchmentDemographics: CatchmentDemographics[]
}

export interface CustomerProfilesTreemapRow {
    name: string,
    type: "Supergroup" | "Group" | "Subgroup",
    supergroupCode?: number | undefined,
    parent: string | undefined,
    numberOfVisitors: number,
    percentageOverallVisitors: number
}

interface DemographicsState {
    isLoading: boolean,
    hasErrors: boolean,
    penPortraits: PenPortrait[],
    catchmentDemographics: CatchmentDemographics[],
    demographicIndicator: DemographicIndicator,
    demographicIndicatorMeasure: DemographicIndicatorMeasure
}

const initialState: DemographicsState = {
    isLoading: false,
    hasErrors: false,
    penPortraits: [],
    catchmentDemographics: [],
    demographicIndicator: DemographicIndicator.AgeStructureGB,
    demographicIndicatorMeasure: DemographicIndicatorMeasure.PercentageOfPopulation
};

const demographicsSlice = createSlice({
        name: "customer/tools/location/demographics",
        initialState,
        reducers: {
            clearPenPortraits: (state) => {
                state.penPortraits = [];
            },
            clearCatchmentDemographics: (state) => {
                state.catchmentDemographics = [];
            },
            setDemographicIndicator: (state, action: PayloadAction<DemographicIndicator>) => {
                state.demographicIndicator = action.payload;
            },
            clearDemographicIndicator: (state) => {
                state.demographicIndicator = initialState.demographicIndicator;
            },
            setDemographicIndicatorMeasure: (state, action: PayloadAction<DemographicIndicatorMeasure>) => {
                state.demographicIndicatorMeasure = action.payload;
            },
            clearDemographicIndicatorMeasure: (state) => {
                state.demographicIndicatorMeasure = initialState.demographicIndicatorMeasure;
            }
        },
        extraReducers: (builder: any) => {
            builder.addCase(loadDemographics.pending, (state: DemographicsState) => {
                state.isLoading = true;
                state.hasErrors = false;
            });
            builder.addCase(loadDemographics.rejected, (state: DemographicsState) => {
                state.isLoading = false;
                state.hasErrors = true;
                state.penPortraits = initialState.penPortraits;
                state.catchmentDemographics = initialState.catchmentDemographics;
                state.demographicIndicator = initialState.demographicIndicator;
                state.demographicIndicatorMeasure = initialState.demographicIndicatorMeasure;
            });
            builder.addCase(loadDemographics.fulfilled, (state: DemographicsState, action: PayloadAction<LoadDemographicsResponse>) => {
                state.isLoading = false;
                state.hasErrors = false;
                state.penPortraits = action.payload.penPortraits;
                state.catchmentDemographics = action.payload.catchmentDemographics;
            });
        }
    }
);

export const { setDemographicIndicator, setDemographicIndicatorMeasure } = demographicsSlice.actions;

export const loadDemographics = createAppAsyncThunk(
    "customer/tools/location/demographics/loadDemographics",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const targetStoreCategoryId = selectTargetStoreCategory(state)?.id;
            const catchmentAccountIds = selectCatchmentAccountIds(state);
            const selectedRetailCentre = selectPinnedLocation(state)?.retailCentre;
            const comparator = selectComparatorsByChapter(state)?.demographics;

            if (selectedRetailCentre?.regionName === 'Northern Ireland') {
                thunkAPI.dispatch(setDemographicIndicator(DemographicIndicator.AgeStructureNI));
            }
            const penPortraitsPromise = thunkAPI.dispatch(loadPenPortraits());
            const catchmentDemographicsPromise = thunkAPI.dispatch(loadCatchmentDemographics(catchmentAccountIds, selectedRetailCentre?.id, comparator, targetStoreCategoryId));
 
            const result = await Promise.all([penPortraitsPromise, catchmentDemographicsPromise]);
            const penPortraits = result[0];
            const catchmentDemographics = result[1];
            const loadDemographicsResponse: LoadDemographicsResponse = {
                penPortraits,
                catchmentDemographics
            };
            return loadDemographicsResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Demographics.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearDemographics = (): AppThunk => (dispatch) => {
    dispatch(demographicsSlice.actions.clearPenPortraits());
    dispatch(demographicsSlice.actions.clearCatchmentDemographics());
    dispatch(demographicsSlice.actions.clearDemographicIndicator());
    dispatch(demographicsSlice.actions.clearDemographicIndicatorMeasure());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.demographics.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.demographics.hasErrors;
};

export const selectPenPortraits = (state: RootState) => {
    const penPortraits = [...state.customer.tools.location.demographics.penPortraits];
    return penPortraits.sort((penPortraitA, penPortraitB) => stringSortExpression(penPortraitA.name, penPortraitB.name, SortDirection.ASC));
};

export const selectCatchmentDemographics = (state: RootState) => {
    return state.customer.tools.location.demographics.catchmentDemographics;
};

export const selectDemographicIndicator = (state: RootState) => {
    return state.customer.tools.location.demographics.demographicIndicator;
};

export const selectDemographicIndicatorMeasure = (state: RootState) => {
    return state.customer.tools.location.demographics.demographicIndicatorMeasure;
};

export const selectDemographicAlignment = createSelector(
    (state: RootState) => selectLocationIsLoading(state),
    (state: RootState) => selectLocationHasErrors(state),
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectTarget(state),
    (isLoading, hasErrors, pinnedLocation, target) => {
        const id = "demographic-alignment";
        let label = "The demography of the selected location's catchment area";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors) {
            return new RagIndicator(id, status, label, "", isLoading, hasErrors);
        }
        if (!pinnedLocation) {
            return new RagIndicator(id, status, label, "No location selected.");
        }
        if (!target?.useDemographics) {
            return new RagIndicator(id, RagIndicatorStatus.NoData, "No target set for Demographics", "");
        }
        const score = pinnedLocation.retailCentre.getRagScore(ScoreField.Demographics);
        switch (score) {
            case 5:
            case 4:
                status = RagIndicatorStatus.Green;
                label = "The demography of the selected location's catchment area aligns strongly with your target demography.";
                break;
            case 3:
            case 2:
                status = RagIndicatorStatus.Amber;
                label = "The demography of the selected location's catchment area aligns averagely with your target demography.";
                break;
            default:
                status = RagIndicatorStatus.Red;
                label = "The demography of the selected location's catchment area aligns weakly with your target demography.";
        }
        return new RagIndicator(id, status, label, "");
    }
);

export const selectDemographicsAlignmentScore = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (pinnedLocation) => {
        return pinnedLocation?.retailCentre.demographicsScore ?? 0;
    }
);

export const selectWeightedPopulationInCatchmentArea = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (state: RootState) => selectCatchmentPopulation(state),
    (pinnedLocation, comparatorsByChapter, catchmentPopulation) => {
        const weightedPopulationInCatchmentArea = {
            isLoading: catchmentPopulation.isLoading,
            hasErrors: catchmentPopulation.hasErrors,
            retailCentre: 0,
            comparator: 0
        };

        if (catchmentPopulation.isLoading || catchmentPopulation.hasErrors) {
            return weightedPopulationInCatchmentArea;
        }

        const retailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.demographics.retailCentreId;

        const retailCentreWeightedPopulation = catchmentPopulation.data.find(item => item.retailCentreId === retailCentreId && (item.isScenario))?.baselinePopulation ?? 0;
        const targetRetailCentreWeightedPopulation = catchmentPopulation.data.find(item => item.retailCentreId === comparatorRetailCentreId && (item.isScenario === comparatorsByChapter?.demographics.scenarioCatchment))?.baselinePopulation ?? 0;

        weightedPopulationInCatchmentArea.retailCentre = retailCentreWeightedPopulation;
        weightedPopulationInCatchmentArea.comparator = targetRetailCentreWeightedPopulation;

        return weightedPopulationInCatchmentArea;
    }
);

export const selectTotalPopulationInCatchmentArea = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (state: RootState) => selectCatchmentPopulation(state),
    (pinnedLocation, comparatorsByChapter, catchmentPopulation) => {
        const populationInCatchmentArea = {
            isLoading: catchmentPopulation.isLoading,
            hasErrors: catchmentPopulation.hasErrors,
            retailCentre: 0,
            comparator: 0
        };

        if (catchmentPopulation.isLoading || catchmentPopulation.hasErrors) {
            return populationInCatchmentArea;
        }

        const retailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.demographics.retailCentreId;

        const retailCentrePopulation = catchmentPopulation.data.find(item => item.retailCentreId === retailCentreId && (item.isScenario))?.population ?? 0;
        const targetRetailCentrePopulation = catchmentPopulation.data.find(item => item.retailCentreId === comparatorRetailCentreId && (item.isScenario === comparatorsByChapter?.demographics.scenarioCatchment))?.population ?? 0;

        populationInCatchmentArea.retailCentre = retailCentrePopulation;
        populationInCatchmentArea.comparator = targetRetailCentrePopulation;

        return populationInCatchmentArea;
    }
);

export const selectCustomerProfilesTreemap = createSelector(
    (state: RootState) => selectCatchmentCustomerProfiles(state),
    (catchmentCustomerProfiles) => {
        if (catchmentCustomerProfiles.isLoading || catchmentCustomerProfiles.hasErrors) {
            return [];
        }
        const customerProfiles = catchmentCustomerProfiles.data;

        const sumNumberOfVisitors = (filteredCustomerProfiles: CatchmentCustomerProfile[]) => {
            return (filteredCustomerProfiles.length > 0)
                ? filteredCustomerProfiles.reduce((accumulator, currentValue) =>
                    accumulator + currentValue.numberOfVisitors ?? 0, 0)
                : 0;
        };

        const totalVisitors = sumNumberOfVisitors(customerProfiles);

        const superGroups = Array.from(new Set(customerProfiles.map(item => item.supergroupName)));
        const groups = Array.from(new Set(customerProfiles.map(item => item.groupName)));
        const subgroups = Array.from(new Set(customerProfiles.map(item => item.subgroupName)));

        let customerProfilesTreemap: CustomerProfilesTreemapRow[] = superGroups.map(supergroupName => {
            const numberOfVisitors = sumNumberOfVisitors(customerProfiles.filter(row => row.supergroupName === supergroupName));
            const percentageOverallVisitors = (totalVisitors > 0) ? numberOfVisitors / totalVisitors * 100 : 0;
            return {
                name: supergroupName,
                type: "Supergroup",
                supergroupCode: customerProfiles.find(row => row.supergroupName === supergroupName)?.supergroupCode,
                parent: undefined,
                numberOfVisitors: numberOfVisitors,
                percentageOverallVisitors: percentageOverallVisitors
            };
        });

        customerProfilesTreemap = customerProfilesTreemap.sort((customerProfileA, customerProfileB) =>
            numberSortExpression(customerProfileA.numberOfVisitors, customerProfileB.numberOfVisitors, SortDirection.DESC));

        customerProfilesTreemap = customerProfilesTreemap.concat(groups.map(groupName => {
            const supergroupName = customerProfiles.find(item => item.groupName === groupName)?.supergroupName;
            const numberOfVisitors = sumNumberOfVisitors(customerProfiles.filter(item => item.groupName === groupName));
            const percentageOverallVisitors = (totalVisitors > 0) ? numberOfVisitors / totalVisitors * 100 : 0;
            return {
                name: groupName,
                type: "Group",
                parent: supergroupName,
                numberOfVisitors: numberOfVisitors,
                percentageOverallVisitors: percentageOverallVisitors
            };
        }));

        customerProfilesTreemap = customerProfilesTreemap.concat(subgroups.map(subgroup => {
            const filteredCustomerProfiles = customerProfiles.filter(item => item.subgroupName === subgroup);
            const groupName = filteredCustomerProfiles.find(item => item.subgroupName === subgroup)?.groupName;
            const numberOfVisitors = sumNumberOfVisitors(filteredCustomerProfiles);
            const percentageOverallVisitors = (totalVisitors > 0) ? numberOfVisitors / totalVisitors * 100 : 0;
            return {
                name: subgroup,
                type: "Subgroup",
                parent: groupName,
                numberOfVisitors: numberOfVisitors,
                percentageOverallVisitors: percentageOverallVisitors
            };
        }));

        return customerProfilesTreemap;
    }
);

export const selectCatchmentAreaDemographic = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectTarget(state),
    (pinnedLocation, target) => {
        const retailCentre = pinnedLocation?.retailCentre;

        return {
            retailCentre: {
                age: retailCentre?.ageCentile ?? 0,
                affluence: retailCentre?.affluenceCentile ?? 0,
                children: retailCentre?.childrenCentile ?? 0,
                diversity: retailCentre?.diversityCentile ?? 0,
                urbanicity: retailCentre?.urbanicityCentile ?? 0
            },
            target: {
                age: target?.age ?? 0,
                affluence: target?.affluence ?? 0,
                children: target?.children ?? 0,
                diversity: target?.diversity ?? 0,
                urbanicity: target?.urbanicity ?? 0
            }
        };
    }
);

export const selectDemographicIndicatorsInCatchmentArea = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    selectCatchmentDemographics,
    (pinnedLocation, comparatorsByChapter, catchmentDemographics) => {
        const retailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.demographics.retailCentreId;

        const retailCentreCatchmentDemographics = retailCentreId
            ? catchmentDemographics.find(demographic => demographic.retailCentreId === pinnedLocation?.retailCentre.id)
            : undefined;

        const benchmarkCatchmentDemographics = comparatorRetailCentreId
            ? catchmentDemographics.find(demographic => demographic.retailCentreId === comparatorRetailCentreId)
            : undefined;

        return {
            retailCentre: retailCentreCatchmentDemographics,
            benchmark: benchmarkCatchmentDemographics
        };
    }
);

export const selectDemographicIndicatorDropdownOptions = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (pinnedLocation) => {
        const demographicIndicators = pinnedLocation?.retailCentre.regionName !== "Northern Ireland" ? new Map<DemographicIndicator, string>([
            [DemographicIndicator.HouseholdIncome, "Household income (Affluence)"],
            [DemographicIndicator.HouseholdOccupancy, "Household occupancy (Affluence)"],
            [DemographicIndicator.HouseholdTenure, "Household tenure (Affluence)"],
            [DemographicIndicator.AgeStructureGB, "Age structure (Age)"],
            [DemographicIndicator.ChildrenAgeStructure, "Children's age structure (Children)"],
            [DemographicIndicator.CountryOfBirth, "Country of birth (Diversity)"],
            [DemographicIndicator.Ethnicity, "Ethnicity (Diversity)"],
            [DemographicIndicator.FirstLanguage, "First language (Diversity)"],
            [DemographicIndicator.DwellingType, "Dwelling type (Urbanicity)"],
            [DemographicIndicator.HouseholdNumberOfCars, "Household number of cars (Urbanicity)"],
            [DemographicIndicator.PopulationDensity, "Population density (Urbanicity)"],
        ]) : new Map<DemographicIndicator, string>([
            [DemographicIndicator.HouseholdTenure, "Household tenure (Affluence)"],
            [DemographicIndicator.AgeStructureNI, "Age structure (Age)"],
            [DemographicIndicator.CountryOfBirth, "Country of birth (Diversity)"],
            [DemographicIndicator.Ethnicity, "Ethnicity (Diversity)"],
            [DemographicIndicator.DwellingType, "Dwelling type (Urbanicity)"],
            [DemographicIndicator.HouseholdNumberOfCars, "Household number of cars (Urbanicity)"],
            [DemographicIndicator.PopulationDensity, "Population density (Urbanicity)"]
        ]);

        return demographicIndicators;
    }
);

export default demographicsSlice;
