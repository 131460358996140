import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createAppAsyncThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { RootState } from "store";

interface FeatureFlags {
    enableCustomerToolsLocationDesktop: boolean,
    enableCustomerToolsLocationMobile: boolean,
    enableCustomerInsightsPortfolioNew: boolean,
    enableCustomerInsightsCost: boolean,
    enableCustomerHighchartsExporting: boolean,
    enableCustomerMapboxExporting: boolean,
    enableCustomerToolsLocationSpendNew: boolean,
    enableCustomerToolsEstate: boolean,
    enableCustomerToolsProduct: boolean,
    enableCustomerToolsReport: boolean,
    enableCustomerToolsLocationRevenuePredictionPlanningData: boolean
}

interface FeatureFlagsState {
    isLoaded: boolean,
    featureFlags: FeatureFlags
}

const initialState: FeatureFlagsState = {
    isLoaded: false,
    featureFlags: {
        enableCustomerToolsLocationDesktop: false,
        enableCustomerToolsLocationMobile: false,
        enableCustomerInsightsPortfolioNew: false,
        enableCustomerInsightsCost: false,
        enableCustomerHighchartsExporting: false,
        enableCustomerMapboxExporting: false,
        enableCustomerToolsLocationSpendNew: false,
        enableCustomerToolsEstate: false,
        enableCustomerToolsProduct: false,
        enableCustomerToolsReport: false,
        enableCustomerToolsLocationRevenuePredictionPlanningData: false
    }
};

const featureFlagsSlice = createSlice({
    name: "featureFlags",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getFeatureFlags.pending, (state: FeatureFlagsState) => {
            state.isLoaded = false;
        });
        builder.addCase(getFeatureFlags.rejected, (state: FeatureFlagsState) => {
            state.isLoaded = true;
        });
        builder.addCase(getFeatureFlags.fulfilled, (state: FeatureFlagsState, action: PayloadAction<FeatureFlags>) => {
            state.isLoaded = true;
            state.featureFlags = action.payload;
        });
    }
});

export const getFeatureFlags = createAppAsyncThunk(
    "featureFlags/getFeatureFlags",
    async (arg, thunkAPI) => {
        const response = await thunkAPI.dispatch(apiGet("/feature-flags"));
        if (response.status === ApiResponseStatus.Ok) {
            return response.data.featureFlags;
        }
        return thunkAPI.rejectWithValue(null);
    }
);

export const selectIsFeatureFlagsLoaded = (state: RootState): boolean => {
    return state.featureFlags.isLoaded;
};

export const selectFeatureFlags = (state: RootState) => {
    return state.featureFlags.featureFlags;
};

export default featureFlagsSlice;
