import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const AgeStructureNI: React.FC = () => {
    const title = "Age structure (Age)";
    const categories = [
        "=<15",
        "16-24",
        "25-44",
        "45-64",
        "65+"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.ageStructureNI;
    const retailCentreValues = retailCentre ? [
        retailCentre.age0to15,
        retailCentre.age16to24,
        retailCentre.age25to44,
        retailCentre.age45to64,
        retailCentre.age65Plus
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.ageStructureNI;
    const benchmarkValues = benchmark ? [
        benchmark.age0to15,
        benchmark.age16to24,
        benchmark.age25to44,
        benchmark.age45to64,
        benchmark.age65Plus
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default AgeStructureNI;
