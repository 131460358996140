import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const selectedStore = useAppSelector(selectStore);
    return (
        <>
            Understand how your {selectedStore?.name} store integrates with your wider estate by analysing how
            its catchment area complements and competes with your existing stores. Identify and quantify
            where you attract new customers and where you may be cannibalising your current portfolio
            to inform lease negotiations and optimise marketing efforts.
        </>
    );
};

export default Subtitle;
