import React from "react";

import { Grid } from "@material-ui/core";

import ExistingStoresFilter from "./ExistingStoresFilter";
import LikelihoodOfVisitingSlider from "./LikelihoodOfVisitingSlider";

const Filter: React.FC = () => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={"auto"}>
                <ExistingStoresFilter />
            </Grid>
            <Grid item xs={6}>
                <LikelihoodOfVisitingSlider />
            </Grid>
        </Grid>
    );
};

export default Filter;
