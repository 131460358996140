import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            The data export module enables you to efficiently manage and extract store data.
            You can customise the export by selecting specific columns, applying filters, and
            sorting the data to fit their needs. Once the desired data set is prepared, it
            can be easily exported for further analysis or reporting.
        </>
    );
};

export default Subtitle;
