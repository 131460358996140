import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import ProductInsight from "./productInsight/ProductInsight";
import Store from "./store/Store";

const ProductOpportunities: React.FC = () => {
    return (
        <Chapter
            title="Product opportunities"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            dataCy="product-opportunities-chapter"
        >
            <Subchapter
                title="Product insight"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="product-insight-subchapter"
            >
                <ProductInsight />
            </Subchapter>
            <Subchapter
                title="Store"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="store-subchapter"
            >
                <Store />
            </Subchapter>
        </Chapter>
    );
};

export default ProductOpportunities;
