import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import ProductMix from "./productMix/ProductMix";
import StoreInsight from "./storeInsight/StoreInsight";

const StoreOpportunities: React.FC = () => {
    return (
        <Chapter
            title="Store opportunities"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            dataCy="store-opportunities-chapter"
        >
            <Subchapter
                title="Store insight"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="store-insight-subchapter"
            >
                <StoreInsight />
            </Subchapter>
            <Subchapter
                title="Product mix"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="product-mix-subchapter"
            >
                <ProductMix />
            </Subchapter>
        </Chapter>
    );
};

export default StoreOpportunities;
