import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Track footfall levels in your selected location and analyse changes over time. 
            Determine its popularity as a destination and identify seasonal patterns of customer flow.
        </>
    );
};

export default Subtitle;
