import { ResultSet } from "@cubejs-client/core";
import { immerable } from "immer";

import { AppThunk } from "appThunk";
import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";

export class SpendCategory {
    [immerable] = true;

    public readonly id: number;
    public readonly name: string;
    private _isSelected: boolean;

    constructor(
        id: number,
        name: string,
        isSelected: boolean
    ) {
        this.id = id;
        this.name = name;
        this._isSelected = isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}

export const loadSpendCategories = (): AppThunk<Promise<SpendCategory[]>> => async (dispatch) => {
    try {
        const queryClientSpendCategories = {
            dimensions: [
                "D_ProductCategory.ProductCategory3",
                "D_ProductCategory.SpendCategory_ID"
            ],
            filters: [{
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "notEquals",
                values: ["-1"]
            }, {
                member: "D_Product.CurrentRecord",
                operator: "equals",
                values: ["Y"]
            }]
        };
        const resultSetClientCategories = await dispatch(cubeLoad(queryClientSpendCategories)) as unknown as ResultSet;
        const rawData = resultSetClientCategories.rawData();
        return rawData.map(row => {
            return new SpendCategory(
                Number(row["D_ProductCategory.SpendCategory_ID"]),
                row["D_ProductCategory.ProductCategory3"],
                true
            );
        });

    } catch (error) {
        dispatch(logError("Error loading SpendCategories.", error));
        throw error;
    }
};
