import React from "react";

import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';
// import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import StoreRoundedIcon from "@material-ui/icons/StoreRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";

import { selectUserInfo } from "modules/auth/authSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppSelector } from "store";

interface Insight {
    name: string,
    icon: React.ReactElement,
    url: string,
    image: string,
    alt: string,
    isAvailable: boolean,
    isAvailableOnMobile: boolean
}

export const useInsights = (): Insight[] => {
    const userInfo = useAppSelector(selectUserInfo);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const insights: Insight[] = [];

    if (userInfo.canAccessPerformance) {
        insights.push({
            name: "Performance",
            icon: <TrendingUpRoundedIcon />,
            url: "/insights/performance",
            image: "/content/customer/insights/performance.png",
            alt: "performance insight",
            isAvailable: true,
            isAvailableOnMobile: true
        });
    }

    if (userInfo.canAccessPortfolio) {
        insights.push({
            name: "Portfolio",
            icon: <StoreRoundedIcon />,
            url: "/insights/portfolio",
            image: "/content/customer/insights/portfolio.png",
            alt: "portfolio insight",
            isAvailable: true,
            isAvailableOnMobile: false
        });
    }

    if (featureFlags.enableCustomerInsightsCost && userInfo.canAccessCost) {
        insights.push({
            name: "Cost",
            icon: <LocalOfferRoundedIcon />,
            url: "/insights/cost",
            image: "/content/customer/insights/cost.png",
            alt: "cost insight",
            isAvailable: true,
            isAvailableOnMobile: false
        });
    }

    // if (userInfo.canAccessRange) {
    //     insights.push({
    //         name: "Range",
    //         icon: <ShoppingBasketRoundedIcon />,
    //         url: "/insights/range",
    //         image: "/content/customer/insights/range.png",
    //         alt: "range insight",
    //         isAvailable: false,
    //         isAvailableOnMobile: false
    //     });
    // }

    return insights;
};
