const GET_USERS_SUCCESS = "spark/customer/account/users/GET_USERS_SUCCESS";
const GET_USERS_FAILURE = "spark/customer/account/users/GET_USERS_FAILURE";
const SET_LICENSES = "spark/customer/account/users/SET_LICENSES";
const CLEAR_LICENSES = "spark/customer/account/users/CLEAR_LICENSES";
const SET_USER = "spark/customer/account/users/SET_USER";
const CLEAR_USER = "spark/customer/account/users/CLEAR_USER";
const SET_RECENTLY_ADDED_USERS = "spark/customer/account/users/SET_RECENTLY_ADDED_USERS";
const CLEAR_RECENTLY_ADDED_USERS = "spark/customer/account/users/CLEAR_RECENTLY_ADDED_USERS";
const SHOW_ADD_USER = "spark/customer/account/users/SHOW_ADD_USER";
const HIDE_ADD_USER = "spark/customer/account/users/HIDE_ADD_USER";
const SHOW_EDIT_USER = "spark/customer/account/users/SHOW_EDIT_USER";
const HIDE_EDIT_USER = "spark/customer/account/users/HIDE_EDIT_USER";
const SHOW_DELETE_USER = "spark/customer/account/users/SHOW_DELETE_USER";
const HIDE_DELETE_USER = "spark/customer/account/users/HIDE_DELETE_USER";
const SHOW_CHANGE_USER_ROLE = "spark/customer/account/users/SHOW_CHANGE_USER_ROLE";
const HIDE_CHANGE_USER_ROLE = "spark/customer/account/users/HIDE_CHANGE_USER_ROLE";
const SET_FILTER = "spark/customer/account/users/SET_FILTER";
const SET_SORT = "spark/customer/account/users/SET_SORT";

const types = {
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    SET_LICENSES,
    CLEAR_LICENSES,
    SET_USER,
    CLEAR_USER,
    SET_RECENTLY_ADDED_USERS,
    CLEAR_RECENTLY_ADDED_USERS,
    SHOW_ADD_USER,
    HIDE_ADD_USER,
    SHOW_EDIT_USER,
    HIDE_EDIT_USER,
    SHOW_DELETE_USER,
    HIDE_DELETE_USER,
    SHOW_CHANGE_USER_ROLE,
    HIDE_CHANGE_USER_ROLE,
    SET_FILTER,
    SET_SORT
};

export default types;
