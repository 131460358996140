import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class ClientRegistration {
    public readonly accountId: string;
    public readonly primaryStoreCategory: string;
    public readonly hasCustomCatchments: boolean;

    constructor(
        accountId: string,
        primaryStoreCategory: string,
        hasCustomCatchments: number | boolean
    ) {
        this.accountId = accountId;
        this.primaryStoreCategory = primaryStoreCategory;
        this.hasCustomCatchments = Boolean(hasCustomCatchments);
    }
}

export const loadClientRegistration = (): AppThunk<Promise<ClientRegistration>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "ClientRegistration.ClientID",
                "ClientRegistration.PrimaryCategory",
                "ClientRegistration.HasCustomCatchments"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return new ClientRegistration(
            rawData[0]["ClientRegistration.ClientID"],
            rawData[0]["ClientRegistration.PrimaryCategory"],
            rawData[0]["ClientRegistration.HasCustomCatchments"],
        );
        
    } catch (error) {
        dispatch(logError("Error loading ClientRegistration.", error));
        throw error;
    }
};
