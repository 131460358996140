import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectSharedPopulation } from "modules/customer/insights/portfolioNew/competition/competitionSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const SharedPopulation: React.FC = () => {
    const { isLoading, hasErrors, data: sharedPopulation } = useAppSelector(selectSharedPopulation);
    const store = useAppSelector(selectStore);
    const valueFormatted = numberFormatter.toDecimalPlaces(sharedPopulation, 1);
    const label = `Shared population in the catchment area of your ${store?.name} store`;

    return (
        <Box width="100%" height="100%" data-cy="shared-population">
            <KPIFact loading={isLoading} error={hasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default SharedPopulation;
