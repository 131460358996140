import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import ProductOpportunities from "./productOpportunities/ProductOpportunities";
import ProductOverviewSubchapter from "./productOverview/ProductOverview";

const ProductOverview: React.FC = () => {
    return (
        <Chapter
            title="Product overview"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            dataCy="product-overview-chapter"
        >
            <Subchapter
                title="Product overview"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="product-insight-subchapter"
            >
                <ProductOverviewSubchapter />
            </Subchapter>
            <Subchapter
                title="Product opportunities"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="store-subchapter"
            >
                <ProductOpportunities />
            </Subchapter>
        </Chapter>
    );
};

export default ProductOverview;
