import React from "react";

import { Box, Container, Typography, useTheme } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "store";
import { clearReport, loadReport } from "modules/customer/tools/report/reportSlice";
import { selectUserInfo } from "modules/auth/authSlice";
import Subtitle from "./Subtitle";
import ExportTable from "./ExportTable";


const Report: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const theme = useTheme();
    const bgcolor = theme.palette.background.default;
    const zIndex = theme.zIndex.drawer;

    React.useEffect(() => {
        dispatch(loadReport());
        return () => {
            dispatch(clearReport());
        };
    }, [dispatch]);

    return (
        <Box maxWidth="100vw" data-cy="pages-customer-tools-report">
            <Container maxWidth={false}>
                <Box display="flex" flexDirection="column" height="100vh">
                    <Box width="100%" position="sticky" top={0} bgcolor={bgcolor} zIndex={zIndex} data-cy="insight-navigation">
                        <br />
                        <Typography variant="subtitle1" component="div" color="textSecondary">
                            {userInfo.companyDisplayName}
                        </Typography>
                        <Box paddingBottom={1} data-cy="title">
                            <Typography variant="h3">Report</Typography>
                        </Box>
                    </Box>
                    <Box paddingBottom={3}>
                        <Typography variant="subtitle1" component="div" data-cy="subtitle">
                            <Subtitle />
                        </Typography>
                    </Box>
                    <Box flexGrow={1} width='100%' paddingBottom={3}>
                        <ExportTable />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Report;
