import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Store } from "./store";

export class RagMetrics {
    public readonly retailCentreId: string;
    public readonly storeCategoryId: number;
    public readonly areaHealth: number;
    public readonly catchment: number;
    public readonly competition: number;
    public readonly footfall: number;

    constructor(
        retailCentreId: string,
        storeCategoryId: number,
        areaHealth: number,
        catchment: number,
        competition: number,
        footfall: number
    ) {
        this.retailCentreId = retailCentreId;
        this.storeCategoryId = storeCategoryId;
        this.areaHealth = areaHealth;
        this.catchment = catchment;
        this.competition = competition;
        this.footfall = footfall;
    }
}

export const loadRagMetrics = (stores: Store[], clientId: string): AppThunk<Promise<RagMetrics[]>> => async (dispatch) => {
    const ragMetrics: RagMetrics[] = [];

    try {
        const retailCentreIDsByStoreCategoryID = new Map<number, number[]>();
        stores.forEach(store => {
            const retailCentreIDs = retailCentreIDsByStoreCategoryID.get(store.storeCategoryID) ?? [];
            retailCentreIDs.push(store.retailCentreID);
            retailCentreIDsByStoreCategoryID.set(store.storeCategoryID, retailCentreIDs);
        });
        const orFilterClause = { or: [] };
        retailCentreIDsByStoreCategoryID.forEach((retailCentreIDs, storeCategoryID) => {
            const andFilterClause = {
                and: [{
                    member: "RC_RagMetrics.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIDs.map(String)
                }, {
                    member: "RC_RagMetrics.StoreCategory_ID",
                    operator: "equals",
                    values: [String(storeCategoryID)]
                }]
            };
            // @ts-ignore
            orFilterClause.or.push(andFilterClause);
        });

        const query = {
            dimensions: [
                "RC_RagMetrics.RetailCentreID",
                "RC_RagMetrics.StoreCategory_ID",
                "RC_RagMetrics.AreaHealth",
                "RC_RagMetrics.Catchment",
                "RC_RagMetrics.Competition",
                "RC_RagMetrics.Footfall"
            ],
            filters: [
                orFilterClause
            ]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        if (rawData.length === 0) {
            return [];
        }

        rawData.forEach(ragMetric => {
            ragMetrics.push({
                retailCentreId: ragMetric["RC_RagMetrics.RetailCentreID"] ?? "",
                storeCategoryId: Number(ragMetric["RC_RagMetrics.StoreCategory_ID"]) ?? 0,
                areaHealth: Number(ragMetric["RC_RagMetrics.AreaHealth"]) ?? 0,
                catchment: Number(ragMetric["RC_RagMetrics.Catchment"]) ?? 0,
                competition: Number(ragMetric["RC_RagMetrics.Competition"]) ?? 0,
                footfall: Number(ragMetric["RC_RagMetrics.Footfall"]) ?? 0
            });
        });

        return ragMetrics;
    } catch (error) {
        dispatch(logError("Error loading RAGMetrics.", error));
        throw error;
    }
};
