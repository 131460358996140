import { ResultSet } from "@cubejs-client/core";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export const loadCostReferenceDate = (): AppThunk<Promise<DateTime>> => async (dispatch) => {
    try {
        const costsQuery = {
            dimensions: ["D_Date.Date"],
            order: [["D_Date.Date", "desc"]],
            filters: [{
                "member": "F_Cost.SumCostValue",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const salesQuery = {
            dimensions: ["D_Date.Date"],
            order: [["D_Date.Date", "desc"]],
            filters: [{
                "member": "F_Sales.SumLineValue",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const costsResultSet = await dispatch(cubeLoad(costsQuery)) as unknown as ResultSet;
        const salesResultSet = await dispatch(cubeLoad(salesQuery)) as unknown as ResultSet;

        const costsResultSetData = costsResultSet.rawData();
        const salesDateString = salesResultSet.rawData()[0]["D_Date.Date"];
        const salesReferenceDate = DateTime.fromISO(salesDateString, { zone: "utc" });

        const salesEndOfMonth = salesReferenceDate.endOf("day").equals(salesReferenceDate.endOf("month").endOf("day"))
            ? salesReferenceDate
            : salesReferenceDate.minus({ months: 1 }).endOf("month");

        const costReferenceDate = costsResultSetData.length !== 0 ?
            DateTime.fromISO(costsResultSetData[0]["D_Date.Date"], { zone: "utc" }) :
            salesEndOfMonth;
        const costEndOfMonth = costReferenceDate.endOf("month");
        const overallReferenceDate = costEndOfMonth < salesEndOfMonth ? costEndOfMonth : salesEndOfMonth;
        return overallReferenceDate;
    } catch (error) {
        dispatch(logError("Error loading CostReferenceDate.", error));
        throw error;
    }
};
