import React from "react";

import PinDropRoundedIcon from "@material-ui/icons/PinDropRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";

import { selectUserInfo } from "modules/auth/authSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppSelector } from "store";

interface Tool {
    name: string,
    icon: React.ReactElement,
    url: string,
    image: string,
    alt: string,
    isAvailable: boolean,
    isAvailableOnMobile: boolean
}

export const useTools = (): Tool[] => {
    const userInfo = useAppSelector(selectUserInfo);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const tools: Tool[] = [];

    if (userInfo.canAccessLocation) {
        tools.push({
            name: "Location",
            icon: <PinDropRoundedIcon />,
            url: "/tools/location",
            image: "/content/customer/tools/location.png",
            alt: "location tool",
            isAvailable: featureFlags.enableCustomerToolsLocationDesktop,
            isAvailableOnMobile: featureFlags.enableCustomerToolsLocationMobile
        });
    }

    if (featureFlags.enableCustomerToolsReport && userInfo.canAccessReport) {
        tools.push({
            name: "Report",
            icon: <AssignmentRoundedIcon />,
            url: "/tools/report",
            image: "/content/customer/tools/report.png",
            alt: "report tool",
            isAvailable: featureFlags.enableCustomerToolsReport,
            isAvailableOnMobile: featureFlags.enableCustomerToolsReport
        });
    }

    if (featureFlags.enableCustomerToolsProduct && userInfo.canAccessProduct) {
        tools.push({
            name: "Product",
            icon: <AssignmentRoundedIcon />,
            url: "/tools/product",
            image: "/content/customer/tools/product.png",
            alt: "product tool",
            isAvailable: featureFlags.enableCustomerToolsProduct,
            isAvailableOnMobile: false
        });
    }

    return tools;
};
