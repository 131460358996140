import React from "react";
import { HashRouter } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";

import { Box, CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LicenseInfo } from '@mui/x-license-pro';

import Customer from "pages/customer/Customer";
import Landing from "pages/landing/Landing";
import Admin from "pages/admin/Admin";

import Backdrop from "./Backdrop";
import Notifications from "./Notifications";
import ScrollToTop from "./ScrollToTop";
import SnackbarProvider from "./SnackbarProvider";
import Setup from "./Setup";

import themes from "themes";

import { useAppSelector } from "store";
import { selectIsAuthenticated, selectIsSupportSession, selectUserInfo } from "modules/auth/authSlice";

const App: React.FC = () => {
    LicenseInfo.setLicenseKey("b7302b75fc85ebca3e70d257b2fa6bedT1JERVI6OTYyNjcsRVhQSVJZPTE3NTUzODE2MDAwMDAsS0VZVkVSU0lPTj0x");
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const isSupportSession = useAppSelector(selectIsSupportSession);
    const userInfo = useAppSelector(selectUserInfo);
    const theme = isAuthenticated ? themes.dark : themes.light;

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <HashRouter>
                        <Box display="flex" data-cy="app">
                            <Setup>
                                {!isAuthenticated && <Landing />}
                                {isAuthenticated && isSupportSession && <Customer />}
                                {isAuthenticated && !isSupportSession && !userInfo.isAppAdmin && <Customer />}
                                {isAuthenticated && !isSupportSession && userInfo.isAppAdmin && <Admin />}
                            </Setup>
                            <Notifications />
                            <Backdrop />
                            <ScrollToTop />
                        </Box>
                    </HashRouter>
                </MuiPickersUtilsProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default App;
