import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import StoreOverviewSubchapter from "./storeOverview/StoreOverview";

const StoreOverview: React.FC = () => {
    return (
        <Chapter
            title="Store overview"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            dataCy="store-overview-chapter"
        >
            <Subchapter
                title="Store overview"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="store-overview-subchapter"
            >
                <StoreOverviewSubchapter />
            </Subchapter>
        </Chapter>
    );
};

export default StoreOverview;
